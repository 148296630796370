import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { addAction } from "@wordpress/hooks";
import {
  Answer,
  BeforeGoingNextArgs,
  CustomFormBlock,
  UploadDocumentParamsCarr,
} from "../../types";
import { ERROR_VALUE } from "../QuizCustomBlocks/ErrorScreenBlock";
import PageLayout from "../../layouts/PageLayout";
import { useFetchBlocks } from "../../hooks/useFetchBlocks";
import { useQuiz } from "../../contexts/QuizContext";
import QuizForm from "../../components/QuizForm";
import { useRendererStoreActions } from "@quillforms/renderer-core";
import {
  BMI_ERROR_MESSAGE,
  EMAIL_OR_PHONE_TAKEN_ERROR_MESSAGE,
  INVALID_VERIFY_CODE_MESSAGE,
  WELCOME_BLOCK_NAME,
} from "../../constants";
import { calculateBMI, valueToQueryParams } from "../../utils";
import { useConditionalBlocks } from "../../hooks/useConditionalBlocks";
import toast from "react-hot-toast";
import clsx from "clsx";
import { ErrorResponse } from "../../services/quizServices";
import { QuizTracking } from "../../components/tracking/QuizTracking";
import { CpaTracking } from "../../components/cpaTracking";
import Cookies from "js-cookie";
import { CookiesKey } from "../../types/cookies";
import { getEntrySourceQueryParam } from "../../utils/getSessionId";

export interface QuizViewProps {
  submitAnswer: (
    blockName: string,
    data: any,
    questionOrder?: number
  ) => Promise<undefined | ErrorResponse>;
  uploadDocument: (params: UploadDocumentParamsCarr) => void;
  fetchBlocks: () => Promise<CustomFormBlock[]>;
  isV2: boolean;
}

const EXPANDED_BLOCK_NAMES = ["before-after-carousel"];

const QuizView: FC<QuizViewProps> = ({
  submitAnswer,
  uploadDocument,
  fetchBlocks,
  isV2,
}) => {
  const { loading, quizBlocks: baseQuizBlocks } = useFetchBlocks(fetchBlocks);
  const { blocksWithConditionals } = useConditionalBlocks(baseQuizBlocks);

  const isReadyToSubmit = useRef(false);

  const { goPrev, goToBlock, setFieldAnswer } = useRendererStoreActions();
  const {
    isValid,
    currentBlockId,
    setIsValid,
    setErrorMessage,
    setHasResponded,
  } = useQuiz();

  const quizBlocks = useMemo(
    () => blocksWithConditionals || baseQuizBlocks,
    [blocksWithConditionals, baseQuizBlocks]
  );

  const firstUnansweredBlockId = useMemo(() => {
    // if (quizBlocks && quizBlocks[1]?.is_answered) {
    if (quizBlocks && quizBlocks.length >= 2) {
      // const firstUnansweredBlock = quizBlocks[quizBlocks.length - 1];
      const firstUnansweredBlock = quizBlocks.find(
        (block) => !block.is_answered && block.name !== WELCOME_BLOCK_NAME
      );
      return firstUnansweredBlock?.id ?? null;
    }
    return null;
  }, [quizBlocks]);

  useEffect(() => {
    if (quizBlocks) {
      quizBlocks.forEach((block) => {
        if (block.answers?.length) {
          block.answers.forEach((answer) => {
            setFieldAnswer(`${answer.question_id}`, answer.value);
          });
        }
      });
    }
  }, [quizBlocks?.length]);

  const handleBeforeGoingNextBlock = useCallback(
    async ({
      currentBlockId,
      answers,

      goNext,
      setIsPending,
    }: BeforeGoingNextArgs) => {
      // setIsWelcomeShown(false);

      const currentAnswer = answers[currentBlockId] as Answer | undefined;

      const isValueError = currentAnswer?.value === ERROR_VALUE;

      const questionOrder =
        (quizBlocks?.findIndex(
          (block) => String(block?.id) === String(currentBlockId)
        ) || 0) + 1;

      if (
        currentAnswer &&
        (currentAnswer?.blockName as any) === "progress-calculation"
      ) {
        submitAnswer(
          currentAnswer.blockName,
          {
            ...currentAnswer,
            id: currentBlockId,
            value: [true],
          },
          questionOrder
        );
        setHasResponded(false);

        const cpaContactsAnswer: any = Object.values(answers).find(
          (answer: any) => answer?.blockName === "contacts-fields-cpa-skip"
        );

        if (!!cpaContactsAnswer?.value) {
          Cookies.set(CookiesKey.PASSED_CPA_QUIZ, "true");

          const params = valueToQueryParams(
            (cpaContactsAnswer?.value as Record<string, string>) ?? {}
          );
          const isStaging =
            window?.location?.hostname?.startsWith("intake-stage");
          const resultsPageBase = isStaging
            ? "front.skinnyrx.com"
            : "skinnyrx.com";

          // redirect to CPA results
          window.location.href = `https://${resultsPageBase}/resultsv3?${getEntrySourceQueryParam()}&${params}`;
        } else {
          // non-cpa flows

          goNext();
        }
        return;
      } else if (!currentAnswer || isValueError || !currentAnswer.isAnswered) {
        if (!currentAnswer) {
          goNext();
        } else if (isValueError) {
          goPrev();
        }
        return;
      }

      if (currentAnswer.blockName === "height-weight-input-fields") {
        const { weight, feet, inches } = currentAnswer.value;
        const bmi = calculateBMI(+weight, +feet, +inches);
        if (bmi < 27) {
          setErrorMessage(BMI_ERROR_MESSAGE);
          return;
        }
        setErrorMessage("");
      }

      const data = { ...currentAnswer, id: currentBlockId };

      if (data.blockName === "custom-attachment") {
        setIsPending(true);
        let allFilesUploadedSuccessfully = true;

        for (const file of data.value.files) {
          try {
            await uploadDocument({
              type: data.value.type,
              file,
            });
          } catch (error) {
            allFilesUploadedSuccessfully = false;
            toast.error(
              error instanceof Error ? error.message : "An error occurred"
            );
            break;
          }
        }

        if (allFilesUploadedSuccessfully) {
          setHasResponded(false);
          goNext();
        }
        setIsPending(false);
        return;
      }

      const error = await submitAnswer(
        currentAnswer.blockName,
        data,
        questionOrder
      );

      if (currentAnswer.blockName?.startsWith("contacts-fields")) {
        if (error && (error?.status === 409 || error?.status === 422)) {
          setErrorMessage(
            error?.status === 409
              ? EMAIL_OR_PHONE_TAKEN_ERROR_MESSAGE
              : INVALID_VERIFY_CODE_MESSAGE
          );
          setIsValid(false);
          isReadyToSubmit.current = false;
        } else {
          // tracking
          try {
            window?.geq?.suppress?.();
          } catch (e) {
            console.error(e);
          }

          isReadyToSubmit.current = true;
          setIsValid(true);
          setErrorMessage("");

          goNext();
        }

        return;
      }

      setHasResponded(false);

      goNext();
    },

    [submitAnswer, quizBlocks]
  );

  addAction(
    "QuillForms.RendererCore.Loaded",
    "navigateToFirstUnansweredBlock",
    function () {
      setTimeout(() => {
        if (firstUnansweredBlockId) {
          goToBlock(firstUnansweredBlockId);
          // setIsWelcomeShown(false);
        }
      }, 1000);
    }
  );

  const currentBlock = quizBlocks?.find((block) => block.id === currentBlockId);

  return (
    <>
      {isV2 ? <QuizTracking /> : <CpaTracking />}

      <PageLayout loading={loading} isWelcomeShown>
        <div className="-mt-24 sm:-mt-38 lg:-mt-40 mb-5 sm:mb-6 lg:mb-7 sm:mx-4 xs:mx-8 lg:mx-6 flex justify-center">
          <div
            className={clsx(
              "w-full sm:w-618 sm:rounded-xl sm:drop-shadow-[0_3px_4px_rgba(0,0,0,0.25)]",
              EXPANDED_BLOCK_NAMES.includes(currentBlock?.name ?? "") &&
                "lg:min-w-[900px]"
            )}
          >
            {quizBlocks && (
              <QuizForm
                shouldRedirect={isV2}
                blocks={quizBlocks}
                disableButton={!isValid}
                beforeGoingNext={handleBeforeGoingNextBlock}
                ref={isReadyToSubmit}
              />
            )}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default QuizView;
