import { FC } from "react";

interface IProps {
  description: string;
}

const Description: FC<IProps> = ({ description }) => {
  return <p className="quiz-description">{description}</p>;
};

export default Description;
