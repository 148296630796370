import { createBrowserRouter } from "react-router-dom";
import { PAGES } from "../../constants";
import QuizPage from "../../pages/QuizPage";
import ErrorPage from "../../pages/ErrorPage";

export const router = createBrowserRouter([
  {
    path: PAGES.home,
    element: <ErrorPage />,
  },
  {
    path: PAGES.form,
    element: <QuizPage type="form" />,
  },
  {
    path: PAGES.quiz,
    element: <QuizPage type="quiz" />,
  },
]);
