import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { router } from "./utils/router";
import registerAllBlocks from "./utils/registerBlockType";
import { Helmet } from "react-helmet";
import { IS_SCRIPT_SITE } from "./constants";

registerAllBlocks();
registerCoreBlocks();

const App = () => {
  return (
    <>
      <Helmet>
        <link
          rel="icon"
          href={
            IS_SCRIPT_SITE
              ? "https://cdn.prod.website-files.com/659408738f3f7a64437267c4/65e54f2e0d5bd9279992f233_favicon%20script.png"
              : "/favicon.ico"
          }
        />
        <meta
          name="description"
          content={IS_SCRIPT_SITE ? "Script Derm" : "Skinny RX Fitness"}
        />
        {IS_SCRIPT_SITE && (
          <title>{IS_SCRIPT_SITE ? "Script Derm" : "SkinnyRx"}</title>
        )}
      </Helmet>
      <RouterProvider router={router} />
      <Toaster position="top-center" />
    </>
  );
};

export default App;
