export const DEFAULT_ERROR_TITLE = "Oops! Something Went Wrong";
export const DEFAULT_ERROR_MESSAGE =
  "We've encountered an unexpected error and couldn't complete your request.";
export const BMI_ERROR_MESSAGE =
  "Sorry, we can only prescribe Semaglutide to individuals with a 27 or higher BMI.";
export const EMAIL_TAKEN_ERROR_MESSAGE = "Seems this email is already used";
export const EMAIL_OR_PHONE_TAKEN_ERROR_MESSAGE =
  "Seems this email or phone already passed that quiz";
export const PHONE_TAKEN_ERROR_MESSAGE =
  "Seems this mobile phone is already used";
export const INVALID_VERIFY_CODE_MESSAGE = "Invalid code";
