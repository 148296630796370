export const fields = [
  {
    row: [
      {label: 'Feet', unit: 'Ft.', key: 'feet'},
      {label: 'Inches', unit: 'In.', key: 'inches'},
    ],
  },
  {
    row: [
      {label: 'Current weight (lbs.)', unit: 'Lbs.', key: 'weight'},
    ],
  },
]
