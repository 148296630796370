import {FC} from 'react'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'

interface GoodbyeScreenDisplayProps {
  id: string;
  attributes?: { title: string, subtitle?: string };
}

const GoodbyeScreenDisplay: FC<GoodbyeScreenDisplayProps> = ({id, attributes}) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <div>
        <h2 className='section-title'>{attributes?.title}</h2>
        <p className='text-base lg:text-lg font-greycliffCF font-normal text-black tracking-[-0.0119em]'>
          {attributes?.subtitle}
        </p>
      </div>
    </QuizBlockLayout>
  )
}

export default GoodbyeScreenDisplay
