import { BlockConfiguration } from "../../../types";
import DisclaimersBlock from "../../../containers/QuizCustomBlocks/DisclaimersBlock";

export const disclaimersConfig: BlockConfiguration = {
  name: "side-effect-acknowledgement",
  supports: {
    editable: true,
    required: true,
  },
  attributes: {
    isAnswered: {
      type: "boolean",
    },
  },
  display: DisclaimersBlock,
};
