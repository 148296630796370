import {FC, useCallback} from 'react'
import LongTextDisplay from '../../components/QuizForm/CustomBlocks/LongTextDisplay'
import {BaseAttributes, QuizBlockProps} from '../../types'
import {useQuiz} from '../../contexts/QuizContext'

interface LongTextBlockAttributes extends BaseAttributes {
  descriptionContent?: string[];
  label?: string;
  placeholder?: string;
}

interface LongTextBlockProps extends QuizBlockProps<LongTextBlockAttributes> {
}

const LongTextBlock: FC<LongTextBlockProps> = ({
  val,
  setVal,
  id,
  attributes,
  setIsAnswered,
}) => {
  const {
    question,
    prompt,
    descriptionContent,
    label,
    placeholder,
  } = attributes
  const {setIsValid} = useQuiz()

  const handleChange = useCallback(
    (value: string) => {
      setVal(value)
      setIsAnswered(!!value)
      setIsValid(!!value)
    }, [])

  return (
    <LongTextDisplay
      id={id}
      question={question}
      prompt={prompt}
      descriptionContent={descriptionContent}
      label={label}
      placeholder={placeholder}
      value={val}
      onChange={handleChange}
    />
  )
}

export default LongTextBlock
