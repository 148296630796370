import type { FormBlock } from "@quillforms/types";
import { CustomFormBlock } from "../types";
import { BlockAttributes } from "@quillforms/types/src/types/form-blocks";

const classMap: Record<string, string> = {
  "custom-welcome-screen": "custom-welcome-screen",
  "select-sex": "select-sex without-footer-button",
  "task-status-list": "without-footer-button",
  "discount-block": "custom-welcome-screen",
  "error-screen": "error-screen",
  "custom-goodbye-screen": "without-footer-button",
  "contacts-fields-cpa-skip": "without-footer-button",
};

const hasInnerBlock = (option: BlockAttributes) => !!option.innerBlock;

export const addCustomClasses = (block: FormBlock) => {
  if (
    block.name === "progress-calculation" ||
    (block.name === "custom-single-choice" &&
      !block.attributes?.textarea &&
      Array.isArray(block.attributes?.options) &&
      !block.attributes?.options.filter(hasInnerBlock).length)
  ) {
    return "without-footer-button";
  }

  return classMap[block.name] || block.attributes?.classnames || "";
};

export const decorateBlocks = (
  blocks: CustomFormBlock[]
): CustomFormBlock[] => {
  return blocks.map((block) => ({
    ...block,
    id: `${block.id}`,
    attributes: {
      ...block.attributes,
      classnames: addCustomClasses(block),
    },
  }));
};

export const calculateBMI = (
  weight: number,
  feet: number,
  inches: number
): number => {
  const heightInInches = feet * 12 + inches;
  return (weight / heightInInches ** 2) * 703;
};

export const valueToQueryParams = (
  value: Record<string, string> | string[][]
) => {
  try {
    const params = new URLSearchParams(value);

    return params.toString();
  } catch (e) {
    console.error(e);
    return "";
  }
};
