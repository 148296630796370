import {BlockConfiguration} from '../../../types'
import LongTextBlock from '../../../containers/QuizCustomBlocks/LongTextBlock'

export const customLongTextConfig: BlockConfiguration = {
  name: 'custom-long-text',
  supports: {
    editable: true,
    required: true,
  },
  attributes: {
    nextBtnLabel: {
      type: 'string',
      'default': 'Next',
    },
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    descriptionContent: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    label: {
      type: 'string',
    },
    placeholder: {
      type: 'string',
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
  },
  display: LongTextBlock,
}
