import {FC} from 'react'
import ProgressBar from '../../ProgressBar'
import StatusTable, {Task} from '../../StatusTable'
import {ProgressBarVariant} from '../../../types'

interface TaskStatusListDisplayProps {
  tasks: Task[];
  title: string;
  progress: number;
}

const TaskStatusListDisplay: FC<TaskStatusListDisplayProps> = ({title, tasks, progress}) => {
  return (
    <div className='gap-4 sm:gap-5 lg:gap-6 flex flex-col'>
      <h2 className='text-2xl sm:text-4xl lg:text-[42px] lg:leading-[45px] font-charter text-dark-purple text-center'>
        {title}
      </h2>
      <div>
        <ProgressBar
          progress={progress}
          max={100}
          variant={ProgressBarVariant.UPLOADING}
        />
      </div>
      <StatusTable tasks={tasks}/>
    </div>
  )
}

export default TaskStatusListDisplay
