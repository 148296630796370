import {BlockConfiguration} from '../../../types'
import ErrorScreenBlock from '../../../containers/QuizCustomBlocks/ErrorScreenBlock'

export const errorScreenConfig: BlockConfiguration = {
  name: 'error-screen',
  supports: {
    editable: true,
  },
  attributes: {
    nextBtnLabel: {
      type: 'string',
    },
    classnames: {
      type: 'string',
      'default': 'error-screen',
    },
    title: {
      type: 'string',
    },
    message: {
      type: 'string',
    },
  },
  display: ErrorScreenBlock,
}
