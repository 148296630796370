import { BlockConfiguration } from "../../../types";
import ContactsCheckoutCustomBlock from "../../../containers/QuizCustomBlocks/ContactsCheckoutBlock";

export const contactFieldsCheckoutConfig: BlockConfiguration = {
  name: "contacts-fields-checkout",
  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
    },
    isAnswered: {
      type: "boolean",
    },
  },

  display: ContactsCheckoutCustomBlock,
};
