import React, { FC } from "react";
import QuizBlockLayout from "../../../../layouts/QuizBlockLayout";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Accordion } from "../../../common/accordion";
import { disclaimersData } from "./common";
import Description from "./components/Description";
import CustomCheckbox from "./components/CustomCheckbox";

interface InputFieldDisplayProps {
  id: string;
  isChecked: { [key: string]: boolean };
  onChange: (name: string) => void;
}

const DisclaimersDisplay: FC<InputFieldDisplayProps> = ({
  id,
  onChange,
  isChecked,
}) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <p className="section-title">
        Please review and agree to the following precautions
      </p>
      <div className="">
        <Description
          description={
            "Read the following for more information about this product and its potential side effects."
          }
        />

        {disclaimersData.map((disclaimer) => {
          return (
            <div className="" key={disclaimer.name + disclaimer.description}>
              <CustomCheckbox
                onChange={onChange}
                isChecked={isChecked[disclaimer.name]}
                name={disclaimer.name}
                label={disclaimer.label}
              />
            </div>
          );
        })}

        {disclaimersData.map((disclaimer) => {
          return (
            <div className="" key={disclaimer.name + disclaimer.description}>
              <Accordion
                className="mt-4"
                title={disclaimer.title}
                body={disclaimer.body}
              />
            </div>
          );
        })}
      </div>
    </QuizBlockLayout>
  );
};

export default DisclaimersDisplay;
