export const disclaimersData = [
  {
    name: "sideEffects",
    title: "Side Effects Acknowledgement precaution",
    label: "I acknowledge the potential side effects",
    description: `Read the following for more information about this product and its
      potential side effects.`,
    body: (
      <div>
        <p className="mb-2">
          Everyone's weight loss journey is unique, and some common side effects
          may include:
        </p>
        <ul className="list-disc list-inside mb-2">
          <li className="mb-1">Nausea</li>
          <li className="mb-1">
            Upset stomach (such as gas, bloating, and indigestion)
          </li>
          <li className="mb-1">Diarrhea</li>
          <li className="mb-1">Constipation</li>
          <li className="mb-1">
            Fatigue, especially during the first few weeks of treatment
          </li>
          <li className="mb-1">Belching</li>
          <li className="mb-1">
            Injection site reactions (redness, swelling, discomfort)
          </li>
          <li className="mb-1">Vomiting</li>
          <li>Stomach (abdominal) pain</li>
        </ul>
        <p className="mb-2">
          These side effects are usually mild to moderate and often improve over
          time as your body gets used to the medication.
        </p>
        <p className="mb-2">
          While it is normal to experience some mild to moderate side effects as
          your body adjusts to the weight loss medication, there are certain
          severe side effects that require immediate medical attention. If you
          experience any of the following, please consult your regular in-person
          healthcare provider immediately:
        </p>
        <ul className="mb-2 list-disc list-inside">
          <li className="mb-1">
            Severe allergic reactions: Such as difficulty breathing, swelling of
            the face or throat, or severe rash.
          </li>
          <li className="mb-1">
            Pancreatitis: Characterized by severe abdominal pain that may spread
            to the back.
          </li>
          <li className="mb-1">
            Kidney problems: Particularly if you experience severe vomiting or
            diarrhea, which can lead to dehydration.
          </li>
          <li className="mb-1">
            Gallbladder problems: Such as gallstones or inflammation. Changes in
            heart rate.
          </li>
          <li>
            Severe gastrointestinal issues: Like persistent, severe nausea,
            vomiting, or diarrhea.
          </li>
        </ul>
        <p className="mb-2">
          By checking the box below, I acknowledge that I have read and
          understood the information provided regarding the potential side
          effects and risks associated with the medication prescribed to me. I
          agree to follow the recommended guidelines and to seek immediate
          medical attention if I experience any severe side effects as outlined.
        </p>
        <p>
          I also understand that it is my responsibility to inform my healthcare
          provider of any unusual or persistent symptoms during my treatment.
          This confirms that I agree to comply with the prescribed treatment
          plan and that I am aware of the necessary precautions to ensure my
          safety and well-being.
        </p>
      </div>
    ),
  },
  {
    name: "pregnancy",
    title: "Pregnancy and Breastfeeding precaution",
    label: "I acknowledge pregnancy and breastfeeding precautions",
    description:
      "Read the following for more information about this product and its potential side effects.",
    body: (
      <div>
        <p className="mb-2">
          The FDA warns that these medications could harm a developing baby if
          you get pregnant while taking them. "The pill" might not work well
          enough on its own because the medication can reduce its effectiveness.
          So, you need to use condoms, an IUD, or an implant for birth control
          instead.
        </p>
        <p className="mb-2">
          Here's what the FDA recommends: For the first month after starting a
          weight loss medication, and for the first month after increasing the
          dose, keep taking your oral contraception and also use a barrier
          method like condoms to prevent pregnancy. Or, you can switch to a
          non-oral method like an IUD or implant before you start the
          medication.
        </p>
        <p className="mb-2">
          When you stop taking the medication, keep using a backup method like
          condoms for two months to make sure the medication is entirely out of
          your system before trying to get pregnant.
        </p>
        <p>
          Also, it's unclear if the medication is safe while breastfeeding. If
          you are breastfeeding, check with your doctor for safe weight loss
          options. By checking the box below, you acknowledge that you
          understand the information above.
        </p>
      </div>
    ),
  },
  {
    name: "telehealthConsent",
    title: "Telehealth Consent",
    label: "I give consent of telehealth usage",
    description:
      "Check here to indicate that you have read and agree to our televisit consent.",
    body: (
      <div>
        <p className="mb-2">
          BY CLICKING “I AGREE,” checking a related box to signify your
          acceptance, using any other ACCEPTANCE protocol presented through the
          service (AS DEFINED BELOW) or otherwise affirmatively accepting these
          Terms and Conditions, YOU ACKNOWLEDGE THAT YOU HAVE READ, ACCEPTED,
          AND AGREED TO BE BOUND BY this agreement. IF YOU DO NOT AGREE TO THESE
          TERMS and conditions, DO NOT create an account or USE THE SERVICE. YOU
          HEREBY GRANT AGENCY AUTHORITY TO ANY PARTY WHO CLICKS ON THE “I AGREE”
          BUTTON or otherwise indicates acceptance to these Terms and Conditions
          ON YOUR BEHALF.
        </p>
        <h2 className="mb-2 font-bold">Purpose</h2>
        <p className="mb-2">
          The purpose of this consent form (“Consent”) is to provide you with
          information about telehealth and to obtain your informed consent to
          the use of telehealth in the delivery of healthcare services to you by
          physicians, physician assistants, and nurse practitioners
          (“Providers”) using the online platforms owned and operated by
          SkinnyRx (the “Service”).
        </p>
        <h2 className="mb-2 font-bold">Use of Telehealth</h2>
        <p className="mb-2">
          Telehealth involves the delivery of healthcare services using
          electronic communications, information technology, or other means
          between a healthcare provider and a patient who are not in the same
          physical location. Telehealth may be used for diagnosis, treatment,
          follow-up, and/or patient education, and may include, but is not
          limited to, one or more of the following: electronic transmission of
          medical records, photo images, personal health information, or other
          data between a patient and a healthcare provider; interactions between
          a patient and healthcare provider via audio, video, and/or data
          communications (such as secure messaging); use of output data from
          medical devices, sound, and video files. Alternative methods of care
          may be available to you, such as in-person services, and you may
          choose an alternative at any time. Always discuss alternative options
          with your Provider.
        </p>
        <h2 className="mb-2 font-bold">Your Relationship with Us</h2>
        <p className="mb-1">
          We are a technology company that makes available to individuals who
          register as users of the Service (“Users”) certain products and
          services sold or offered by SkinnyRx or by third-party medical
          providers, pharmacies, or other vendors. Our Service provides access
          to prescription fulfillment services offered by several licensed
          pharmacies (the “Pharmacies”). We may also provide you with a means of
          connecting to one or more medical groups who provide healthcare
          services through the Platform (the “Medical Groups”). These medical
          groups employ or contract with physicians and allied health
          professionals who offer certain healthcare services through the
          Platform (“Providers”). By accepting this Agreement, you acknowledge
          and agree that any services you receive from the Pharmacies, Medical
          Groups, or Providers through the Platform are also subject to this
          Agreement, and that the Pharmacies, Medical Groups, and Providers are
          third-party beneficiaries of this Agreement.
        </p>
        <p className="mb-1">
          With respect to the Pharmacies, the Medical Groups, and the Providers,
          we act solely as a technology platform to connect you with the
          products and services offered by the Pharmacies, Medical Groups, and
          Providers through the Service. We do not control or influence the
          practice of medicine by the Medical Groups or any Providers, each of
          whom is solely responsible for the medical care and treatment he or
          she provides to you. SkinnyRx makes no representations or warranties
          about the suitability, reliability, timeliness, or accuracy of the
          medical care and treatment provided by the Medical Groups or any
          Providers. By accepting this Agreement, you acknowledge and agree that
          SkinnyRx is not a healthcare provider and that by using the Service,
          you are not entering into a doctor-patient or other health care
          provider-patient relationship with SkinnyRx.
        </p>
        <p className="mb-1">
          By accepting this Agreement, you acknowledge and agree that the
          Medical Group and/or Providers may send you messages, reports, and
          emails via the Service regarding your diagnosis and/or treatment. You
          further understand and agree that it is your sole responsibility to
          monitor and respond to these messages, reports, and emails and that
          neither SkinnyRx nor the Medical Group nor any Provider will be
          responsible in any way and you will not hold SkinnyRx, the Medical
          Group, or any Provider liable for any loss, injury, or claims of any
          kind resulting from your failure to read or respond to these messages
          or for your failure to comply with any treatment recommendations or
          instructions from the Medical Group or your Provider(s).
        </p>
        <p className="mb-2">
          While you are not establishing a doctor-patient or other health care
          provider-patient relationship with SkinnyRx, by using the Service, you
          are establishing a direct customer relationship with SkinnyRx to use
          the Platform and to purchase any non-prescription products or
          non-medical services sold directly to you by SkinnyRx through the
          Platform. In connection with such relationship, you may provide to us,
          or cause to be provided to us on your behalf, personal information,
          including health information, that is subject to use by us in
          accordance with our Privacy Policy. Please refer to the “Privacy
          Policy” section and the “Protected Information” section below for
          additional information.
        </p>
        <h2 className="mb-2 font-bold">Anticipated Benefits</h2>
        <p className="mb-2">
          The use of telehealth may have the following possible benefits: Making
          it easier and more efficient for you to access medical care and
          treatment for the conditions treated by your Provider(s); allowing you
          to obtain medical care and treatment by Provider(s) at times that are
          convenient for you; and enabling you to interact with Provider(s)
          without the necessity of an in-office appointment.
        </p>
        <h2 className="mb-2 font-bold">Potential Risks</h2>
        <p className="mb-2">
          While the use of telehealth in the delivery of care can provide
          potential benefits for you, there are also potential risks associated
          with the use of telehealth and other technology. These risks include,
          but may not be limited to, the following: the quality, accuracy, or
          effectiveness of the services you receive from your Provider could be
          limited; technology, including the Service, may contain bugs or other
          errors, including ones which may limit functionality, produce
          erroneous results, render part or all of such technology, including
          the Service, unavailable or inoperable, produce incorrect records,
          transmissions, data, or content, or cause records, transmissions,
          data, or content to be corrupted or lost; failures of technology may
          also impact your Provider(s)' ability to correctly diagnose or treat
          your medical condition; the inability of your Provider(s) to conduct
          certain tests or assess vital signs in-person may in some cases
          prevent the Provider(s) from providing a diagnosis or treatment or
          from identifying the need for emergency medical care or treatment for
          you; your Provider(s) may not be able to provide medical treatment for
          your particular condition, and you may be required to seek alternative
          healthcare or emergency care services; delays in medical
          evaluation/treatment could occur due to unavailability of your
          Provider(s) or deficiencies or failures of the technology or
          electronic equipment used; the electronic systems or other security
          protocols or safeguards used could fail, causing a breach of privacy
          of your medical or other information; given regulatory requirements in
          certain jurisdictions, your Provider(s) diagnosis and/or treatment
          options, especially pertaining to certain prescriptions, may be
          limited; a lack of access to all of your medical records may result in
          adverse drug interactions or allergic reactions or other judgment
          errors.
        </p>
        <h2 className="mb-2 font-bold">Follow Up Care; Emergency Situations</h2>
        <div className="mb-2">
          <p className="mb-1">
            If the situation is an emergency, call 911. In some situations,
            telehealth is not an appropriate method of care. If you require
            immediate or urgent care, you must seek care at an emergency room
            facility or other provider equipped to deliver urgent or emergent
            care. If you are not experiencing an emergency or do not require
            immediate or urgent care, you can communicate with Providers through
            the secure message service in the Platform. If a technical failure
            prevents you from communicating with your Providers through the
            Platform, you should call the following number:
          </p>
          <p>
            Phone: **1-888-979-9580** (M-F 8:00am-12:00am EST) (Sat-Sun
            8:00am-8:00pm)
          </p>
        </div>
        <h2 className="mb-2 font-bold">Data Privacy and Protection</h2>
        <p className="mb-2">
          The electronic systems used in the Service will incorporate network
          and software security protocols to protect the privacy and security of
          your information and will include measures to safeguard data against
          intentional or unintentional corruption. Personal information that
          identifies you or contains protected health information will not be
          disclosed to any third party without your consent, except as
          authorized by law for the purposes of consultation, treatment,
          payment/billing, and certain administrative purposes, or as otherwise
          set forth in your Provider’s Notice of Privacy Practices.
        </p>
        <h2 className="mb-2 font-bold">Your Acknowledgements</h2>
        <p>
          By clicking “I Agree,” checking a related box to signify your
          acceptance, using any other acceptance protocol presented through the
          Service, or otherwise affirmatively accepting this consent, you are
          agreeing and providing your consent with respect to the following:
          Healthcare services provided to you by Providers via the Service will
          be provided by telehealth. Certain technology, including the Service,
          may be used while still in a beta testing and development phase, and
          before such technology is a final and finished product. Technology
          used to deliver care, including the Service, may contain bugs or other
          errors, including ones which may limit functionality, produce
          erroneous results, render part or all of such technology unavailable
          or inoperable, produce incorrect records, transmissions, data, or
          content, or cause records, transmissions, data, or content to be
          corrupted or lost, any or all of which could limit or otherwise impact
          the quality, accuracy, and/or effectiveness of the medical care or
          other services that you receive from your Provider(s). The delivery of
          healthcare services via telehealth is an evolving field and the use of
          telehealth or other technology in your medical care and treatment from
          Provider(s) may include uses of technology different from those
          described in this Consent or not specifically described in this
          Consent. No potential benefits from the use of telehealth or other
          technology or specific results can be guaranteed. Your condition may
          not be cured or improved, and in some cases, may get worse. There are
          limitations in the provision of medical care and treatment via
          telehealth and technology, including the Service, and you may not be
          able to receive diagnosis and/or treatment through telehealth for
          every condition for which you seek diagnosis and/or treatment. There
          are potential risks to the use of telehealth and other technology,
          including but not limited to the risks described in this Consent. You
          have the opportunity to discuss the use of telehealth, including the
          Service, with your Provider(s).
        </p>
      </div>
    ),
  },
];
