import { useEffect } from "react";
import { QuizBlockProps } from "../../types";
import ApprovedBannerDisplay from "../../components/QuizForm/CustomBlocks/ApprovedBannerDisplay";

interface ContactsBlockProps extends QuizBlockProps<{}> {}

const ApprovedBannerBlock = ({
  id,
  setIsAnswered,
  setVal,
}: ContactsBlockProps) => {
  useEffect(() => {
    setVal({ answer: true });
    setIsAnswered(true);
  }, []);

  return <ApprovedBannerDisplay id={id} />;
};

export default ApprovedBannerBlock;
