import {BlockConfiguration} from '../../../types'
import HeightWeightInputFieldsBlock from '../../../containers/QuizCustomBlocks/HeightWeightInputFieldsBlock'

export const heightWeightInputFieldsConfig: BlockConfiguration = {
  name: 'height-weight-input-fields',
  supports: {
    editable: true,
  },
  attributes: {
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
  },
  display: HeightWeightInputFieldsBlock,
}
