import { FC } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import benefitsHeroBanner from "../../../assets/images/approvedHeroBanner2.jpg";

interface InputFieldDisplayProps {
  id: string;
}

const ApprovedBannerDisplay: FC<InputFieldDisplayProps> = ({ id }) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <p className="section-title">Approved in minutes, shipped overnight.</p>

      <img src={benefitsHeroBanner} alt="SkinnyRx benefits" />
    </QuizBlockLayout>
  );
};

export default ApprovedBannerDisplay;
