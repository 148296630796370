import {FC} from 'react'
import classNames from 'classnames'
import {ProgressBarVariant} from '../../types'

interface ProgressBarProps {
  progress: number;
  max: number;
  variant?: ProgressBarVariant;
}

const ProgressBar: FC<ProgressBarProps> = ({
  variant = ProgressBarVariant.DEFAULT,
  progress = 0,
  max = 0,
}) => {
  const percentage = max > 0 ? Math.round((progress / max) * 100) : 0

  return (
    <>
      <div
        className={classNames({
          'h-3 sm:h-4 w-full bg-whisper rounded-full overflow-hidden': variant === ProgressBarVariant.DEFAULT,
          'h-7 sm:h-10 lg:h-12 w-full bg-cloud overflow-hidden': variant === ProgressBarVariant.UPLOADING,
        })}
      >
        <div
          className={classNames(
            'transition-all duration-500 ease-in-out',
            {
              'bg-lilac h-full rounded-full': variant === ProgressBarVariant.DEFAULT,
              'bg-light-green h-full': variant === ProgressBarVariant.UPLOADING,
            },
          )}
          style={{width: progress ? `${percentage}%` : '1rem'}}
        />
      </div>
      {variant === ProgressBarVariant.UPLOADING && (
        <div className='mt-1.5 sm:mt-2 text-sm sm:text-[15px] font-azoSansWeb text-light-gray leading-none'>
          Total Progress: {percentage}%
        </div>
      )}
    </>
  )
}

export default ProgressBar
