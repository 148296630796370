import { useEffect, useRef, useState } from "react";
import { QuizBlockProps } from "../../types";
import ResultCalculationDisplay from "../../components/QuizForm/CustomBlocks/ResultCalculationDisplay";
import { useQuiz } from "../../contexts/QuizContext";

import Slide2 from "../../assets/images/proggress/2.png";
import Slide3 from "../../assets/images/proggress/3.png";

import { useCurrentBlock } from "@quillforms/renderer-core";

const SLIDES_DELAY = 3;
const slides = [Slide2, Slide3];

interface ContactsBlockProps extends QuizBlockProps<{}> {}

const ResultCalculationBlock = ({
  id,
  next,
  setVal,
  setIsAnswered,
}: ContactsBlockProps) => {
  const { setIsValid } = useQuiz();
  const [timerTicks, setTimerTicks] = useState(0);

  const timer = useRef<number | null>(null);

  const currentBlock = useCurrentBlock();
  const currentBlockId = currentBlock?.id;

  useEffect(() => {
    // first opens page
    if (id === currentBlockId) {
      if (!timer.current) {
        // disable Next question button
        setIsValid(false);

        setIsAnswered(false);

        timer.current = window.setInterval(() => {
          setTimerTicks((t) => t + 1);
        }, 1000);
      }
    } else {
      // closes page
      if (timer.current) {
        window.clearInterval(timer.current);
        timer.current = null;
      }
    }

    // cleanup
    return () => {
      if (timer.current) {
        window.clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [currentBlockId, id]);

  useEffect(() => {
    // end of play
    if (timerTicks >= slides.length * SLIDES_DELAY) {
      // if (timerTicks >= 4) {
      setVal({ answer: true });
      setIsValid(true);
      setIsAnswered(true);

      next();
      if (timer.current) {
        window.clearInterval(timer.current);
        timer.current = null;
      }
    }
  }, [timerTicks]);

  let slideIdx = Math.min(
    Math.floor(timerTicks / SLIDES_DELAY),
    slides.length - 1
  );

  return (
    <ResultCalculationDisplay
      progressNumber={(timerTicks / SLIDES_DELAY / slides.length) * 100}
      slide={slides[slideIdx]}
      id={id}
    />
  );
};

export default ResultCalculationBlock;
