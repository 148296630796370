export const API_PATHS = {
  // form
  FORM_BLOCKS: (id: string) => `/api/v1/form/${id}/blocks`,
  POST_ANSWER: "/api/v1/form/answer",
  // quiz
  QUIZ_BLOCKS: (id: string) => `/api/v1/quiz/${id}/blocks`,
  // QUIZ_POST_ANSWER: "/api/v1/quiz/answer",

  UPLOAD_DOCUMENT: "/api/v1/documents/upload",
  VERIFY_CONTACTS: "/api/v1/form/verify-contacts",
};
