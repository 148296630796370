import { FC } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import benefitsHeroBanner from "../../../assets/images/benefitsHeroBanner.jpg";

interface InputFieldDisplayProps {
  id: string;
}

const BenefitsCompareDisplay: FC<InputFieldDisplayProps> = ({ id }) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <p className="section-title ">
        Are you ready to lose weight safely & quickly?
      </p>

      <img src={benefitsHeroBanner} alt="SkinnyRx benefits" />
    </QuizBlockLayout>
  );
};

export default BenefitsCompareDisplay;
