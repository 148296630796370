import {FC} from 'react'
import DiscountDisplay from '../../components/QuizForm/CustomBlocks/DiscountDisplay'
import {QuizBlockProps} from '../../types'

interface DiscountBlockAttributes {
  title: string;
}

interface DiscountBlockProps extends QuizBlockProps<DiscountBlockAttributes> {
}

const DiscountBlock: FC<DiscountBlockProps> = ({id, attributes}) => {
  const {title} = attributes

  return (
    <DiscountDisplay
      id={id}
      title={title}
    />
  )
}

export default DiscountBlock
