import {FC, useEffect} from 'react'
import ErrorScreenDisplay from '../../components/QuizForm/CustomBlocks/ErrorScreenDisplay'
import {QuizBlockProps} from '../../types'

export interface ErrorScreenBlockAttributes {
  title: string;
  message: string;
}

interface ErrorScreenBlockProps extends QuizBlockProps<ErrorScreenBlockAttributes> {
}

export const ERROR_VALUE = 'error'

const ErrorScreenBlock: FC<ErrorScreenBlockProps> = ({attributes, setVal, setIsAnswered}) => {

  useEffect(() => {
    setVal(ERROR_VALUE)
    setIsAnswered(true)
  }, [])

  return (
    <ErrorScreenDisplay
      title={attributes.title}
      message={attributes.message}
    />
  )
}

export default ErrorScreenBlock
