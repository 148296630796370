export enum CookiesKey {
  AFFILIATE_UTM_MERGED = "aff_utm_merge",
  SESSION_ID = "session_id",
  QUIZ_TRANSACTION_ID = "c3",
  QUIZ_AFFILIATE_ID = "quiz_affiliate",
  QUIZ_AFFILIATE_CMP = "c3_provider",
  PASSED_CPA_QUIZ = "passed_cpa_quiz",
  IS_FIRE = "is_fire",
  ENTRY_SOURCE = "entry_source",
  QUIZ_EV_CLICK_ID = "quiz_ev_click_id",
}

export enum QuizAffiliateProvider {
  BW = "bw",
  BW_CPA = "bw_cpa",
  TO = "to",
  TO_CPA = "to_cpa",
  YEP = "yep",
  YEP_CPA = "yep_cpa",
  AFFI = "affi",
  ICE = "ice",
  AP_CPA = "ap",
  CANDY_CPA = "candy",
}
