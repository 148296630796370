import {FC} from 'react'

interface QuestionContentDisplayProps {
  question: string;
  prompt?: string;
  customHTML?: string;
}

const QuestionContentDisplay: FC<QuestionContentDisplayProps> = ({question, prompt, customHTML}) => {
  return (
    <div className='mb-3.5 sm:mb-5 lg:mb-6'>
      <h2 className='section-title'>{question}</h2>
      {prompt && <p className='quiz-description'>{prompt}</p>}
      {customHTML && <div className='quiz-description' dangerouslySetInnerHTML={{__html: customHTML}}/>}
    </div>
  )
}

export default QuestionContentDisplay
