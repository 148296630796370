import { BlockConfiguration } from "../../../types";
import BenefitsCompareBlock from "../../../containers/QuizCustomBlocks/BenefitsCompareBlock";

export const benefitsCompareConfig: BlockConfiguration = {
  name: "benefits-compare-hero",
  supports: {
    editable: false,
  },
  attributes: {},
  display: BenefitsCompareBlock,
};
