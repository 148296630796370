import { FC, ReactNode } from "react";
import FullScreenLoader from "../../components/FullScreenLoader";
import Logo from "../../components/Logo";
import Footer from "../../components/Footer";
import classNames from "classnames";
import { IS_SCRIPT_SITE } from "../../constants";

interface PageLayoutProps {
  children: ReactNode;
  loading: boolean;
  isWelcomeShown?: boolean;
  error?: boolean;
}

const PageLayout: FC<PageLayoutProps> = ({
  children,
  loading,
  isWelcomeShown,
}) => {
  return (
    <div className="relative overlay flex flex-col justify-between min-h-screen">
      <header>
        <FullScreenLoader isLoading={loading} />
        <div className="h-40 sm:h-60 lg:h-80 bg-light-pink flex justify-center">
          <Logo
            wrapperClassName={classNames(
              "w-24 sm:w-40 lg:w-52 -mt-4 lg:-mt-7",
              IS_SCRIPT_SITE && "mt-8 lg:mt-8"
            )}
          />
        </div>
      </header>
      <main className="flex-grow mb-2 sm:mb-3 lg:mb-4">{children}</main>
      {isWelcomeShown && <Footer />}
    </div>
  );
};

export default PageLayout;
