import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import ContactsFieldsDisplay from "../../components/QuizForm/CustomBlocks/ContactsDisplay";
import { QuizBlockProps } from "../../types";
import { useQuiz } from "../../contexts/QuizContext";
import { EMAIL_REGEX, NAME_FIELD_REGEX, PHONE_REGEX } from "../../constants";
import { saveUserInfoValues } from "../../utils/userInfoData";
import { validateDob } from "../../utils/validateField/dob";

const NUMBER_OF_ANSWERS = 5;

const DEFAULT_ERROR_MESSAGE = "Required";
const FIRST_NAME_ERROR_MESSAGE = "Enter valid first name";
const SECOND_NAME_ERROR_MESSAGE = "Enter valid last name";
const EMAIL_ERROR_MESSAGE = "Enter valid email";
const PHONE_ERROR_MESSAGE =
  "Invalid phone number format. It should be in the format (123) 456-7890";

const ContactsCheckoutCustomBlock = ({
  val,
  setVal,
  id,
  setIsAnswered,
}: QuizBlockProps) => {
  const [values, setValues] = useState(val || {});
  const [error, setError] = useState<Record<string, string | boolean>>({});

  const { setIsValid, setErrorMessage } = useQuiz();

  useEffect(() => {
    saveUserInfoValues(values);
  }, [values]);

  const isFormAnswered = () => {
    const isAllAnswered =
      Object.values(values).filter(Boolean).length === NUMBER_OF_ANSWERS;

    const isNoErrors = Object.values(error).every(
      (errorMessage) => !errorMessage
    );

    return isAllAnswered && isNoErrors;
  };

  useEffect(() => {
    if (values) {
      const areValid = isFormAnswered();

      setIsAnswered(areValid);
      setVal(areValid ? values : undefined);
    }
  }, [values, error]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });

    if (error[name]) {
      validateField(name, value);
    }
  };

  const setFieldError = (field: string, errorMessage: string | boolean) => {
    setError((errors) => ({ ...errors, [field]: errorMessage }));
  };

  const validateField = (name: string, value: string) => {
    if (!value || value.length < 2) {
      setFieldError(name, DEFAULT_ERROR_MESSAGE);
      return;
    }

    if (name === "dob") {
      const dobErrorMessage = validateDob(value || "");

      setFieldError(name, dobErrorMessage || false);
      return;
    }

    if (name === "phone") {
      const isValid = PHONE_REGEX.test(value || "");
      setFieldError(name, !isValid && PHONE_ERROR_MESSAGE);
      return;
    }

    if (name === "email") {
      const isValid = EMAIL_REGEX.test(value || "");
      setFieldError(name, !isValid && EMAIL_ERROR_MESSAGE);
      return;
    }

    if (name === "secondName") {
      const isValid = NAME_FIELD_REGEX.test(value);
      setFieldError(name, !isValid && SECOND_NAME_ERROR_MESSAGE);
      return;
    }

    if (name === "firstName") {
      const isValid = NAME_FIELD_REGEX.test(value);
      setFieldError(name, !isValid && FIRST_NAME_ERROR_MESSAGE);
      return;
    }

    setFieldError(name, DEFAULT_ERROR_MESSAGE);

    // valid field
    // setFieldError(name, "");
  };

  const handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    setIsValid(true);
    setErrorMessage("");
    validateField(name, values[name]);
  };

  return (
    <ContactsFieldsDisplay
      values={values}
      error={error}
      onInputChange={handleInputChange}
      onBlur={handleBlur}
      id={id}
      isCheckoutFunnel
    />
  );
};

export default ContactsCheckoutCustomBlock;
