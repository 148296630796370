import { FC } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import slide2 from "../../../assets/images/beforeAfter/item2.png";

interface InputFieldDisplayProps {
  id: string;
}

const BeforeAfterFieldsDisplay: FC<InputFieldDisplayProps> = ({ id }) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <div className="flex flex-col justify-center">
        <p className="!m-0 section-title text-center">
          Real Users, Real Results.
        </p>
        <p className="my-3 text-md">
          &quot;I can't thank SkinnyRx enough. Everything from their product,
          shipping time, and customer service is unbeatable. I'm down 34 lbs
          since I started using them 4 months ago!&quot;
          <br />
          <br />- Rachel, 32
        </p>
        <img
          src={slide2}
          className=" max-h-[400px]  mx-auto"
          alt={"Rachel before after"}
        />
      </div>
    </QuizBlockLayout>
  );
};

export default BeforeAfterFieldsDisplay;
