import {Task} from '../../../components/StatusTable'
import {TaskStatusConstants} from '../../../components/StatusTable/TaskStatus.constants'

export const initialTasks: Task[] = [{name: 'Searching Doctor Network 1'}]

export const finalTasks: Task[] = [
  {
    name: 'Searching Doctor  Network 1',
    status: TaskStatusConstants.ERROR,
    statusLabel: 'No Discounts Available',
  },
  {
    name: 'Searching Discount  Network 2',
    status: TaskStatusConstants.COMPLETED,
    statusLabel: '70% Discount Found',
  },
  {
    name: 'Checking Discount Availability',
    status: TaskStatusConstants.COMPLETED,
    statusLabel: 'Adding Your 70% Discount',
  },
  {
    name: 'Waiting For System Confirmation',
    status: TaskStatusConstants.COMPLETED,
    statusLabel: 'Completed A 70% Discount!',
    note: 'Just $29.99 for Your First Purchase',
  },
]
