import { FC } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import {
  availableStatesAbbreviations,
  fetchAutocompletePlaceData,
  IAddressAutocompleteFields,
} from "./common";
import { clsx } from "clsx";

const MAPS_API_KEY =
  process.env.REACT_APP_MAPS_API_KEY ??
  "AIzaSyBUuMk9ahQ6Wl0-KcaMvMur21lIqTjGEq0";

export type InputProps = {
  addressValue: string;
  errorMessages?: boolean | string | string[];
  onChange: (
    addressValue: string,
    addressParsedValue: IAddressAutocompleteFields
  ) => void;
};

export const AddressAutocompleteInput: FC<InputProps> = ({
  addressValue,
  onChange,
  errorMessages: errorMessagesProp,
}) => {
  const errorMessages = Array.isArray(errorMessagesProp)
    ? errorMessagesProp
    : [errorMessagesProp].filter(Boolean);

  const handleAddressInput = (value: string, action: { action: string }) => {
    // trigger only on actual user input
    // and ignore on dropdown item click
    if (action.action === "input-change") onChange(value, { address: value });
  };

  const handleAddressSelect = async (selectedOption: any) => {
    const selectedAddress = selectedOption.value;
    const placeId = selectedAddress.place_id;
    const addressValue =
      selectedAddress.structured_formatting.main_text || selectedOption;

    const addressData = await fetchAutocompletePlaceData(placeId);

    onChange(addressValue, {
      ...addressData,
      address: addressValue,
    });
  };

  return (
    <div>
      <label
        className={clsx(
          "text-sm mb-1 text-left block",
          errorMessages.length && "text-red"
        )}
      >
        {"Address line 1"}
      </label>
      <GooglePlacesAutocomplete
        apiKey={MAPS_API_KEY}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us"],
          },
        }}
        selectProps={{
          filterOption: ({ data }) => {
            const state = data.value.terms?.at(-1)?.value || "";

            return !!availableStatesAbbreviations.includes(state);
          },
          components: { DropdownIndicator: null },
          value: !addressValue
            ? ""
            : ({ label: addressValue, value: addressValue } as any),
          placeholder: "",
          noOptionsMessage: () => null,
          defaultInputValue: addressValue,
          onInputChange: handleAddressInput,
          onChange: handleAddressSelect,
          openMenuOnClick: false,
          classNames: {
            control: (props: any) => {
              return clsx(
                "p-1 sm:p-2  !text-base !sm:text-lg !border-[3px] w-full !border-soft !rounded-lg",
                props.isFocused &&
                  "!border-medium-green !outline-none !shadow-none	",
                errorMessages.length && "border-red border-[1px]"
              );
            },

            container: () => {
              return "text-left";
            },
          },
        }}
        onLoadFailed={(error) =>
          console.error("Could not load Google API", error)
        }
        debounce={300}
        minLengthAutocomplete={2}
        apiOptions={{ language: "en", region: "us" }}
      />
      {errorMessages?.map((errorMessage, index) => (
        <span
          key={index}
          className="absolute bottom-[-10px] left-0 text-[10px] leading-[1px] text-error"
        >
          {errorMessage}
        </span>
      ))}
    </div>
  );
};
