import axios, { AxiosError, AxiosResponse } from "axios";
import { apiClient } from "../apiClient";
import { API_PATHS } from "../../constants";
import {
  CustomFormBlock,
  PostAnswerParams,
  UploadDocumentParams,
  UploadDocumentResponse,
} from "../../types";
import { CookiesKey, QuizAffiliateProvider } from "../../types/cookies";
import Cookies from "js-cookie";

const QuizAPI = {
  async fetchBlocks(path: string): Promise<CustomFormBlock[]> {
    try {
      const response: AxiosResponse<CustomFormBlock[]> = await apiClient.get(
        path
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch blocks");
    }
  },

  async postAnswer(
    answerData: PostAnswerParams,
    questionOrder?: number
  ): Promise<any> {
    try {
      const response = await apiClient.post(API_PATHS.POST_ANSWER, {
        ...answerData,
      });

      const isFireHeader = (response as any)?.headers?.get?.("X-Is-Fire") ?? "";

      if (String(isFireHeader)) {
        Cookies.set(CookiesKey.IS_FIRE, isFireHeader);
      }

      if (!!questionOrder) {
        try {
          const cpaAffiliateProvider = Cookies.get(
            CookiesKey.QUIZ_AFFILIATE_CMP
          ) as QuizAffiliateProvider;

          if (cpaAffiliateProvider === QuizAffiliateProvider.AP_CPA) {
            window?.fbq?.(
              "trackSingleCustom",
              "2389439141250150",
              `Question${questionOrder}Completion`
            );
            window?.fbq?.(
              "trackSingleCustom",
              "1135726667481333",
              `Question${questionOrder}Completion`
            );
            window?.fbq?.(
              "trackSingleCustom",
              "436103438892412",
              `Question${questionOrder}Completion`
            );
            window?.fbq?.(
              "trackSingleCustom",
              "917049193283139",
              `Question${questionOrder}Completion`
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // check if email is taken and send phone verification OTP
  async verifyContacts(body: {
    email: string;
    phone_number: string;
  }): Promise<any> {
    try {
      const response = await apiClient.post(API_PATHS.VERIFY_CONTACTS, {
        ...body,
      });

      if (response.status === 200) {
        return "";
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 409) {
        const respBody = (error as AxiosError).response?.data as any;

        if (respBody?.conflict_property === "phone_number") return "phone";
        if (respBody?.conflict_property) return respBody.conflict_property;

        throw error;
      }
      throw error;
    }
  },

  async uploadDocument({
    userId,
    type,
    file,
  }: UploadDocumentParams): Promise<UploadDocumentResponse> {
    const formData = new FormData();

    formData.append("file", file);

    if (userId) {
      formData.append("userId", userId);
    }

    formData.append("type", type);

    try {
      const response = await apiClient.post<UploadDocumentResponse>(
        API_PATHS.UPLOAD_DOCUMENT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.message);
      }
      throw new Error("Error uploading document" + error);
    }
  },
};

export const { fetchBlocks, postAnswer, uploadDocument, verifyContacts } =
  QuizAPI;
