import { FC, useCallback, useEffect, useState } from "react";
import HeightWeightInputFieldsDisplay from "../../components/QuizForm/CustomBlocks/HeightWeightInputFieldsDisplay";
import { ONLY_NUMBERS } from "../../constants";
import { QuizBlockProps } from "../../types";

interface HeightWeightInputFieldsBlockProps extends QuizBlockProps {
  val: Record<string, string> | undefined;
}

type Validators = Record<InputKey, (value: string) => string>;

enum InputKey {
  FEET = "feet",
  WEIGHT = "weight",
  INCHES = "inches",
}

const NUMBER_OF_ANSWERS = 3;

const HeightWeightInputFieldsBlock: FC<HeightWeightInputFieldsBlockProps> = ({
  val,
  setVal,
  id,
  attributes,
  setIsAnswered,
}) => {
  const { question, prompt } = attributes;
  const [values, setValues] = useState(val || {});

  useEffect(() => {
    if (values) {
      const isAnswered =
        Object.values(values).filter(Boolean).length === NUMBER_OF_ANSWERS;
      setIsAnswered(isAnswered);
      setVal(isAnswered ? values : undefined);
    }
  }, [values, setIsAnswered]);

  const generalValidator = (min: number, max: number) => (value: string) => {
    if (Number(value) < min) {
      return String(min);
    }
    if (Number(value) > max) {
      return String(max);
    }
    return value;
  };

  const validators: Validators = {
    [InputKey.FEET]: generalValidator(3, 8),
    [InputKey.WEIGHT]: generalValidator(100, 800),
    [InputKey.INCHES]: generalValidator(0, 11),
  };

  const handleInputChange = useCallback(
    (inputValue: string, inputKey: string) => {
      let cleanedInput = inputValue.replace(ONLY_NUMBERS, "");
      setValues({ ...values, [inputKey]: cleanedInput });
    },
    [values]
  );

  const handleBlur = useCallback(
    (inputValue: string, inputKey: string) => {
      if (inputValue === "") {
        return;
      }
      let cleanedInput = Number(inputValue).toString();
      cleanedInput = validators[inputKey as InputKey](cleanedInput);
      setValues({ ...values, [inputKey]: cleanedInput });
    },
    [values]
  );

  return (
    <HeightWeightInputFieldsDisplay
      id={id}
      values={values}
      question={question}
      prompt={prompt}
      onChange={handleInputChange}
      onBlur={handleBlur}
    />
  );
};

export default HeightWeightInputFieldsBlock;
