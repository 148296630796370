import { BlockConfiguration } from "../../../types";
import ContactsCpaCustomBlock from "../../../containers/QuizCustomBlocks/ContactsCpaBlock";

export const contactFieldsCpaConfig: BlockConfiguration = {
  name: "contacts-fields-cpa",
  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
    },
    isAnswered: {
      type: "boolean",
    },
  },
  display: ContactsCpaCustomBlock,
};
