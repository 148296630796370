import { BlockConfiguration } from "../../../types";
import BeforeAfterCarouselBlock from "../../../containers/QuizCustomBlocks/BeforeAfterCarouselBlock";

export const beforeAfterCarouselConfig: BlockConfiguration = {
  name: "before-after-carousel",
  supports: {
    editable: false,
  },
  attributes: {},
  display: BeforeAfterCarouselBlock,
};
