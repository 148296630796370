import {FC, useEffect, useRef, useState} from 'react'
import TaskStatusListDisplay from '../../components/QuizForm/CustomBlocks/TaskStatusListDisplay'
import {QuizBlockProps} from '../../types'
import {TaskStatusConstants} from '../../components/StatusTable/TaskStatus.constants'
import {Task} from '../../components/StatusTable'
import {finalTasks, initialTasks} from './constants/TaskStatusListBlock.constants'
import {useQuiz} from '../../contexts/QuizContext'

interface TaskStatusListBlockAttributes {
  title: string;
}

interface TaskStatusListBlockProps extends QuizBlockProps<TaskStatusListBlockAttributes> {

}

const TaskStatusListBlock: FC<TaskStatusListBlockProps> = ({id, attributes, next}) => {
  const {title} = attributes

  const [currentTasks, setCurrentTasks] = useState<Task[]>(initialTasks)
  const [step, setStep] = useState(0)
  const totalSteps = finalTasks.length * 3 // Each task has 3 steps: name, pending status, final status and label
  const intervalRef = useRef<number>(0)
  const {currentBlockId} = useQuiz()

  useEffect(() => {
    if (currentBlockId === id) {
      intervalRef.current = window.setInterval(() => {
        setStep((prevStep) => prevStep + 1)
      }, 1000)
    }

    return () => {
      if (intervalRef.current) window.clearInterval(intervalRef.current)
    }
  }, [currentBlockId, id])

  useEffect(() => {
    if (step > totalSteps) {
      if (intervalRef.current) window.clearInterval(intervalRef.current)
      next()
    } else {
      const taskIndex = Math.floor((step - 1) / 3)
      const stepType = (step - 1) % 3
      const updatedTasks = [...currentTasks]

      switch (stepType) {
        case 0:
          if (!updatedTasks[taskIndex]) {
            updatedTasks.push({...finalTasks[taskIndex], status: undefined, statusLabel: ''})
          }
          break
        case 1:
          updatedTasks[taskIndex].status = TaskStatusConstants.PENDING
          break
        case 2:
          updatedTasks[taskIndex].status = finalTasks[taskIndex].status
          updatedTasks[taskIndex].statusLabel = finalTasks[taskIndex].statusLabel
          updatedTasks[taskIndex].note = finalTasks[taskIndex].note
          break
        default:
          break
      }

      setCurrentTasks(updatedTasks)
    }
  }, [step, totalSteps])

  return (
    <TaskStatusListDisplay
      title={title}
      tasks={currentTasks}
      progress={(step / totalSteps) * 100}
    />
  )
}

export default TaskStatusListBlock
