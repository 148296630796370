import {FC} from 'react'

interface DescriptionBlockProps {
  descriptionBlock: { title?: string, content?: string[], customHTML?: string }[];
}

const DescriptionBlock: FC<DescriptionBlockProps> = ({descriptionBlock}) => {
  return (
    <>
      {descriptionBlock.map(({title, content, customHTML}, i) => (
        <div key={i} className='mb-3 sm:mb-3.5 lg:mb-4'>
          {title && <p className='text-lg lg:text-xl font-bold'>{title}</p>}
          {content?.map((contentItem) => (
            <p key={contentItem} className='text-base lg:text-[17px] font-normal'>
              {contentItem}
            </p>
          ))}
          {customHTML && (
            <div
              className='text-base lg:text-[17px] font-normal'
              dangerouslySetInnerHTML={{__html: customHTML}}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default DescriptionBlock
