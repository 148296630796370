import type {FormTheme} from "@quillforms/types";

export const formTheme: Partial<FormTheme> = {
  font: "Roboto",
  buttonsBgColor: "#F5D552",
  questionsColor: "#000",
  answersColor: "#0aa7c2",
  buttonsFontColor: "#000",
  buttonsFontSize: {
    lg: '20px',
    sm: '16px'
  },
  buttonsPadding: {
    top: {
      lg: '20px',
      sm: '16px'
    },
    bottom: {
      lg: '20px',
      sm: '16px'
    },
    left: {
      lg: '70px',
      sm: '60px'
    },
    right: {
      lg: '70px',
      sm: '60px'
    },
  },
  questionsDescriptionMargin: {
    lg: '20px',
    sm: '16px'
  },
  buttonsBorderRadius: 6,
  errorsFontColor: "#fff",
  errorsBgColor: "#f00",
  progressBarFillColor: "#CEB9FF",
  progressBarBgColor: "#F2F3F4",
}
