import { MutableRefObject, forwardRef } from "react";
import { Form } from "@quillforms/renderer-core";
import { FormObj } from "@quillforms/renderer-core/src/types";
import { Answer, QuizFormProps } from "../../types";
import { formSettings } from "./formSettings";
import { formTheme } from "./formTheme";
import { formMessages } from "./formMessages";
import "../../styles/quizForm.css";
import { useQuiz } from "../../contexts/QuizContext";
import Cookies from "js-cookie";
import { CookiesKey } from "../../types/cookies";
import { valueToQueryParams } from "../../utils";
import { getEntrySourceQueryParam } from "../../utils/getSessionId";
import { IS_SCRIPT_SITE, resultsPageBase } from "../../constants";

const initialFormObj: FormObj = {
  blocks: [],
  settings: formSettings,
  theme: formTheme,
  messages: formMessages,
  hiddenFields: {},
  customCSS: ``,
};

const QuizForm = forwardRef<boolean, QuizFormProps>(
  ({ blocks, disableButton, shouldRedirect, beforeGoingNext }, isReadyRef) => {
    const { isValid, errorMessage } = useQuiz();

    return (
      <Form
        formId={1}
        applyLogic={false}
        formObj={{
          ...initialFormObj,
          blocks,
          customCSS: `${
            disableButton
              ? ".renderer-core-button{background-color: #E4E4E4;cursor: default;color: #8E8E8E;} .renderer-core-button:hover{background-color: #E4E4E4;box-shadow:unset!important;}"
              : ""
          }`,
        }}
        beforeGoingNext={beforeGoingNext}
        onSubmit={(data: any, { completeForm, setIsSubmitting }: any) => {
          setIsSubmitting(true);
          const answers = Object.values((data.answers as Answer[]) ?? {});

          if (
            !isValid ||
            errorMessage ||
            (!(isReadyRef as MutableRefObject<boolean | null>)?.current &&
              !IS_SCRIPT_SITE)
          ) {
            setIsSubmitting(false);
            return false;
          }

          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();

            if (IS_SCRIPT_SITE) {
              const isDeadEndFlow = document.location.pathname?.endsWith("/2");

              // flow 1  - suer finishes quiz
              if (isDeadEndFlow) return;

              const productSearchParam = new URLSearchParams(
                window.location.search
              ).get("product");

              // flow 2 - user gets to checkout
              return (window.location.href = `${resultsPageBase}/checkout?product=${productSearchParam}`);
            }

            if (shouldRedirect) {
              const isOsClonePage =
                document.location.pathname?.endsWith("9505");

              const isRClonePage = document.location.pathname?.endsWith("9507");

              const isCpaPage = answers?.find(
                (a) => a.blockName === "contacts-fields-cpa"
              );

              const isFreeCartPage = answers?.find(
                (a) => a.blockName === "shipping-fields-checkout"
              );

              if (isFreeCartPage) {
                const shippingAnswer = answers?.find(
                  (a) => a.blockName === "shipping-fields-checkout"
                );

                const contactAnswer = answers?.find(
                  (a) => a.blockName === "contacts-fields-checkout"
                );

                const params = valueToQueryParams({
                  ...((shippingAnswer?.value as Record<string, string>) ?? {}),
                  ...((contactAnswer?.value as Record<string, string>) ?? {}),
                  lastName: (
                    (contactAnswer?.value as Record<string, string>) ?? {}
                  )?.secondName,
                });

                // redirect to free checkout results
                window.location.href = `${resultsPageBase}/checkout-v0?${getEntrySourceQueryParam()}&${params}`;
                return;
              }

              if (isCpaPage) {
                Cookies.set(CookiesKey.PASSED_CPA_QUIZ, "true");

                const params = valueToQueryParams(
                  (isCpaPage?.value as Record<string, string>) ?? {}
                );

                let resultsSubPath = "resultsv3";

                if (isOsClonePage) resultsSubPath = "results-os";
                if (isRClonePage) resultsSubPath = "resultsv4";

                // redirect to CPA results
                window.location.href = `${resultsPageBase}/${resultsSubPath}?${getEntrySourceQueryParam()}&${params}`;

                return;
              }
              const isFire = Cookies.get(CookiesKey.IS_FIRE);

              let cplParams = "";

              // meaning in cookie we set invalid string
              if (isFire === "1" || isFire === "0") {
                cplParams = `isFire=${isFire}`;
              }
              // redirect to CPL results
              window.location.href = `${resultsPageBase}/results?${getEntrySourceQueryParam()}&${cplParams}`;
            }
          }, 500);
        }}
      />
    );
  }
);

export default QuizForm;
