export const DEFAULT_MAX_FILES = 1
export const DEFAULT_MAX_FILE_SIZE_MB = 50

export const BYTES_PER_MB = 1024 * 1024 // Number of bytes in 1MB

export const videoConstraints = {
  aspectRatio: 16 / 9,
  width: 1920,
  height: 1080,
  facingMode: 'user',
}
