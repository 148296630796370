import { useEffect } from "react";
import { QuizBlockProps } from "../../types";
import BenefitsCompareDisplay from "../../components/QuizForm/CustomBlocks/BenefitsCompareDisplay";

interface ContactsBlockProps extends QuizBlockProps<{}> {}

const BenefitsCompareBlock = ({
  id,
  setIsAnswered,
  setVal,
}: ContactsBlockProps) => {
  useEffect(() => {
    setVal({ answer: true });
    setIsAnswered(true);
  }, []);

  return <BenefitsCompareDisplay id={id} />;
};

export default BenefitsCompareBlock;
