import {FC} from 'react'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'
import Textarea from '../../Textarea'

interface LongTextDisplayProps {
  id: string;
  question: string;
  value: string | undefined;
  onChange: (value: string) => void;
  prompt?: string;
  descriptionContent?: string[];
  label?: string;
  placeholder?: string;
}

const LongTextDisplay: FC<LongTextDisplayProps> = ({
  id,
  question,
  prompt,
  descriptionContent,
  label,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <QuizBlockLayout
      currentBlockId={id}
      question={question}
      prompt={prompt}
    >
      <div className='mb-4 sm:mb-6 lg:mb-7'>
        {descriptionContent?.map((item, i) => (
          <p key={i} className='quiz-description'>
            {item}
          </p>
        ))}
      </div>
      <Textarea
        inputStateIndicator
        id={id}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </QuizBlockLayout>
  )
}

export default LongTextDisplay
