import {IoCheckmarkCircle, IoCloseCircle} from 'react-icons/io5'
import {LuLoader2} from 'react-icons/lu'

export enum TaskStatusConstants {
  COMPLETED = 'completed',
  PENDING = 'pending',
  ERROR = 'error',
}

export const taskStatusIcon = {
  [TaskStatusConstants.COMPLETED]: <IoCheckmarkCircle className='w-5 h-5 text-teal'/>,
  [TaskStatusConstants.PENDING]: <LuLoader2 className='w-7 h-7 mx-auto text-blue animate-spin'/>,
  [TaskStatusConstants.ERROR]: <IoCloseCircle className='w-5 h-5'/>,
}

export const taskStatusColor = {
  [TaskStatusConstants.COMPLETED]: 'text-primary-green',
  [TaskStatusConstants.PENDING]: 'text-cloud',
  [TaskStatusConstants.ERROR]: 'text-brick-red',
}

