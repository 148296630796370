import { Link } from "react-router-dom";
import { HIPAA } from "../../assets/images";
import { infoBannerLinks } from "../../constants";

const InfoBanner = () => {
  return (
    <div className="rounded-info-block-footer">
      <img src={HIPAA} alt="HIPAA compliant" className="w-[60px] sm:w-[75px]" />
      <div className="text-sm  flex flex-col leading-tight">
        <p>Your Information Is </p>
        {infoBannerLinks.map(({ name, path }) => (
          <Link
            key={name}
            to={path}
            className="cursor-pointer  underline decoration-1"
          >
            {name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default InfoBanner;
