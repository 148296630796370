import { FC } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import doctorPrescribeBanner from "../../../assets/images/doctorPrescribeBanner.png";

interface InputFieldDisplayProps {
  id: string;
}

const DoctorPrescribeBannerDisplay: FC<InputFieldDisplayProps> = ({ id }) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <p className="section-title" style={{ fontWeight: 500 }}>
        <b>The medications that our licensed weight loss specialists prescribe</b> are recommended by
        Harvard, Mayo Clinic, WebMD & the National Institutes of Health.
      </p>

      <img src={doctorPrescribeBanner} alt="SkinnyRx benefits" />
    </QuizBlockLayout>
  );
};

export default DoctorPrescribeBannerDisplay;
