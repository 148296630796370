import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import { InputField } from "../../common/input";
import { useQuiz } from "../../../contexts/QuizContext";

interface InputFieldDisplayProps {
  id: string;
  values: Record<string, string>;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: SyntheticEvent<HTMLInputElement>) => void;
  error: Record<string, string | boolean>;
  onBackPressed: () => void;
  onResendCode: () => Promise<void>;
}

const RESEND_COOLDOWN = 5;

const ContactsVerifyDisplay: FC<InputFieldDisplayProps> = ({
  id,
  values,
  error,
  onInputChange,
  onBlur,
  onBackPressed,
  onResendCode,
}) => {
  const { setErrorMessage } = useQuiz();

  const resendCooldownTimer = useRef<number | null>();
  const [cooldownSecondsLeft, setCooldownSecondsLeft] =
    useState(RESEND_COOLDOWN);
  const [isNewCodeSent, setNewCodeSent] = useState(false);

  useEffect(() => {
    if (cooldownSecondsLeft <= 0) {
      setCooldownSecondsLeft(RESEND_COOLDOWN);
      setNewCodeSent(false);

      if (resendCooldownTimer.current) {
        clearInterval(resendCooldownTimer.current);
        resendCooldownTimer.current = null;
      }
    }
  }, [cooldownSecondsLeft]);

  const onGetNewCode = async () => {
    try {
      setErrorMessage("");

      await onResendCode();

      setNewCodeSent(true);
      setCooldownSecondsLeft(RESEND_COOLDOWN);

      resendCooldownTimer.current = window.setInterval(() => {
        setCooldownSecondsLeft((v) => v - 1);
      }, 1000);
    } catch (e) {
      setErrorMessage("Unknown error occurred. Please try later.");
    }
  };

  return (
    <QuizBlockLayout currentBlockId={id} onPrevClick={onBackPressed}>
      <div className=" flex flex-col-reverse items-center	 w-full justify-center gap-4 desktop:p-8">
        <div className="text-center max-w-[100%] w-[480px] flex-col flex gap-2">
          <p className="section-title text-lg">
            We sent you a confirmation code at {values["phone"]}
          </p>

          <InputField
            value={values["verificationCode"]}
            error={error["verificationCode"]}
            name="verificationCode"
            onChange={onInputChange}
            onBlur={onBlur}
            label="Confirmation code"
            type="verifyCode"
            required
          />

          <div className="text-left mt-2 text-base">
            {isNewCodeSent ? (
              <span>
                We sent you another code. You'll be able to try again in{" "}
                {cooldownSecondsLeft} seconds
              </span>
            ) : (
              <>
                <span>Didn't get the code? </span>
                <span
                  onClick={onGetNewCode}
                  className="underline cursor-pointer text-blue-2"
                >
                  Get new code
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </QuizBlockLayout>
  );
};

export default ContactsVerifyDisplay;
