import React, { FC, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import DescriptionBlock from "./DescriptionBlock";
import Textarea from "../../Textarea";
import classNames from "classnames";

interface SingleChoiceDisplayProps {
  id: string;
  options: {
    value: string;
    label: string;
    innerBlock?: { label: string; type: string; placeholder?: string };
  }[];
  value: string | undefined;
  textareaValue: string | undefined;
  onChangeTextarea: (value: string) => void;
  onChange: (value: string) => void;
  question?: string;
  prompt?: string;
  descriptionBlock?: {
    title?: string;
    content?: string[];
    customHTML?: string;
  }[];
  textarea?: { label: string; placeholder?: string };
  note?: string;
}

const SingleChoiceDisplay: FC<SingleChoiceDisplayProps> = ({
  id,
  question,
  prompt,
  descriptionBlock,
  options,
  value: currentValue,
  textarea,
  note,
  textareaValue,
  onChange,
  onChangeTextarea,
}) => {
  const nodeRef = useRef(null);

  return (
    <QuizBlockLayout currentBlockId={id} question={question} prompt={prompt}>
      {descriptionBlock && (
        <DescriptionBlock descriptionBlock={descriptionBlock} />
      )}
      <div className="my-4 sm:my-6 lg:my-8 flex flex-col space-y-3 sm:space-y-4">
        {options.map(({ value, label, innerBlock }) => (
          <label
            key={value}
            htmlFor={value}
            className={classNames(
              "group p-3 sm:p-5 lg:p-6 pr-6 sm:pr-10 lg:pr-11 flex flex-col border border-slate-gray rounded-md cursor-pointer hover:border-dark-purple overflow-hidden",
              {
                "border-dark-purple": currentValue === value,
              }
            )}
            onClick={() => onChange(value)}
          >
            <div className="flex">
              <div className="custom-radio">
                <input
                  type="radio"
                  id={value}
                  value={value}
                  checked={currentValue === value}
                  onChange={({ target }) => onChange(target.value)}
                />
                <div className="radio-custom group-hover:after" />
              </div>
              <div className="pl-6 sm:pl-7 lg:pl-8 grow">
                <p className="text-lg sm:text-xl font-greycliffCF text-charcoal font-bold break-words overflow-wrap tracking-[-0.0113em]">
                  {label}
                </p>
                {/* {value === "none" && (
                  <span className="block text-sm ">(Best answer)</span>
                )} */}
                <CSSTransition
                  nodeRef={nodeRef}
                  in={innerBlock && currentValue === value}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <label
                    ref={nodeRef}
                    htmlFor={innerBlock?.label}
                    className="gap-1.5 sm:gap-2.5 mt-4 sm:mt-5 flex flex-col"
                  >
                    <span className="text-sm sm:text-base lg:text-[17px] font-greycliffCF font-normal">
                      {innerBlock?.label}
                    </span>
                    {innerBlock?.type === "textarea" && (
                      <Textarea
                        id={innerBlock.label}
                        placeholder={innerBlock.placeholder}
                        value={textareaValue}
                        onChange={onChangeTextarea}
                      />
                    )}
                  </label>
                </CSSTransition>
              </div>
            </div>
          </label>
        ))}
      </div>
      {textarea && (
        <div className="mb-5 sm:mb-6 lg:mb-7 -mt-1">
          <Textarea
            inputStateIndicator
            id={id}
            value={textareaValue}
            onChange={onChangeTextarea}
            placeholder={textarea.placeholder}
            label={textarea.label}
          />
        </div>
      )}
      {note && (
        <div className="text-base lg:text-[17px] lg:leading-[25px]">
          <strong>Note: </strong>
          <span className="font-helveticaNeue"> {note}</span>
        </div>
      )}
    </QuizBlockLayout>
  );
};

export default SingleChoiceDisplay;
