import { BlockConfiguration } from "../../../types";
import DoctorPrescribeBannerBlock from "../../../containers/QuizCustomBlocks/DoctorPrescribeBannerBlock";

export const doctorPrescriptionBannerConfig: BlockConfiguration = {
  name: "doctors-prescribe-banner",
  supports: {
    editable: false,
  },
  attributes: {},
  display: DoctorPrescribeBannerBlock,
};
