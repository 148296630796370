import { FC } from "react";
import { fields } from "../../../containers/QuizCustomBlocks/constants/HeightWeightInputFieldsBlock.constants";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import { BaseAttributes } from "../../../types";

interface HeightWeightInputFieldsDisplayProps extends BaseAttributes {
  id: string;
  values: Record<string, string>;
  onChange: (inputValue: string, inputKey: string) => void;
  onBlur?: (inputValue: string, inputKey: string) => void;
  onFocus?: (inputValue: string, inputKey: string) => void;
}

const HeightWeightInputFieldsDisplay: FC<
  HeightWeightInputFieldsDisplayProps
> = ({ values, id, question, prompt, onChange, onBlur, onFocus }) => {
  return (
    <QuizBlockLayout currentBlockId={id} question={question} prompt={prompt}>
      {fields.map(({ row }, i: number) => (
        <div
          key={i}
          className="w-full flex justify-between items-center gap-2.5 sm:gap-3 lg:gap-4"
        >
          {row?.map(({ label, unit, key }) => (
            <div key={key} className="w-full flex-1">
              <label className="text-sm mb-2">{label}</label>
              <div className="relative flex items-center">
                <input
                  value={values?.[key] || ""}
                  onChange={({ target }) => onChange(target.value, key)}
                  onBlur={({ target }) => onBlur?.(target.value, key)}
                  onFocus={({ target }) => onFocus?.(target.value, key)}
                  type="text"
                  className="p-2 sm:p-3 text-lg sm:text-xl border-[3px] w-full border-soft rounded-lg focus:border-medium-green focus:outline-none"
                  inputMode="tel"
                />
                {unit && (
                  <div className="absolute right-1 pr-5 pl-2 py-2 bg-white">
                    {unit}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </QuizBlockLayout>
  );
};

export default HeightWeightInputFieldsDisplay;
