import { logo } from "../../assets/images";

const Logo = ({ wrapperClassName = "" }) => {
  return (
    <div className={`${wrapperClassName} relative z-10`}>
      <img src={logo} alt="Logo" className="mx-auto" />
    </div>
  );
};

export default Logo;
