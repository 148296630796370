import { BlockConfiguration } from "../../../types";
import ApprovedBannerBlock from "../../../containers/QuizCustomBlocks/ApprovedBannerBlock";

export const approvedBannerConfig: BlockConfiguration = {
  name: "approved-banner-hero",
  supports: {
    editable: false,
  },
  attributes: {},
  display: ApprovedBannerBlock,
};
