import {FC} from 'react'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'
import {QuizBlockProps} from '../../../types'

interface WelcomeScreenAttributes {
  title: string;
  subtitle?: string;
}

interface WelcomeScreenDisplayProps extends QuizBlockProps<WelcomeScreenAttributes> {
}

const WelcomeScreenDisplay: FC<WelcomeScreenDisplayProps> = ({id, attributes}) => {
  return (
    <QuizBlockLayout currentBlockId={id} withProgressBar={false}>
      <>
        <h1 className='quiz-title'>{attributes.title}</h1>
        <div className='text-base sm:text-lg font-greycliffCF font-normal text-dark-purple-2 text-center'>
          {attributes?.subtitle}
        </div>
      </>
    </QuizBlockLayout>
  )
}

export default WelcomeScreenDisplay
