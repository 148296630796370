import {BlockConfiguration} from '../../../types'
import DiscountBlock from '../../../containers/QuizCustomBlocks/ DiscountBlock'

export const discountBlockConfig: BlockConfiguration = {
  name: 'discount-block',
  supports: {
    editable: false,
  },
  attributes: {
    nextBtnLabel: {
      type: 'string',
      'default': 'Continue to questions',
    },
    title: {
      type: 'string',
    },
  },
  display: DiscountBlock,
}
