import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import ContactsFieldsDisplay from "../../components/QuizForm/CustomBlocks/ContactsDisplay";
import { QuizBlockProps } from "../../types";
import { useQuiz } from "../../contexts/QuizContext";
import {
  EMAIL_REGEX,
  FIRST_NAME_ERROR_MESSAGE,
  NAME_FIELD_REGEX,
  PHONE_REGEX,
  SECOND_NAME_ERROR_MESSAGE,
} from "../../constants";
import { saveUserInfoValues } from "../../utils/userInfoData";
import classNames from "classnames";

const NUMBER_OF_ANSWERS = 4;

const DEFAULT_ERROR_MESSAGE = "Required";
const EMAIL_ERROR_MESSAGE = "Enter valid email";
const PHONE_ERROR_MESSAGE =
  "Invalid phone number format. It should be in the format (123) 456-7890";

const ContactsCpaCustomBlock = ({
  val,
  setVal,
  id,
  setIsAnswered,
  next,
}: QuizBlockProps) => {
  const [values, setValues] = useState(val || {});
  const [error, setError] = useState<Record<string, string | boolean>>({});

  const { setIsValid, setErrorMessage, hasResponded, setHasResponded } =
    useQuiz();

  useEffect(() => {
    saveUserInfoValues(values);
  }, [values]);

  const isFormAnswered = () => {
    const isAllAnswered =
      Object.values(values).filter(Boolean).length === NUMBER_OF_ANSWERS;

    const isNoErrors = Object.values(error).every(
      (errorMessage) => !errorMessage
    );

    return isAllAnswered && isNoErrors;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });

    if (error[name]) {
      validateField(name, value);
    }
  };

  const setFieldError = (field: string, errorMessage: string | boolean) => {
    setError((errors) => ({ ...errors, [field]: errorMessage }));
  };

  const validateField = (name: string, value: string) => {
    if (!value) {
      setFieldError(name, DEFAULT_ERROR_MESSAGE);
      return;
    }

    // no special validation for first and second names
    if (name === "phone") {
      const isValid = PHONE_REGEX.test(value || "");
      setFieldError(name, !isValid && PHONE_ERROR_MESSAGE);
      return;
    }

    if (name === "email") {
      const isValid = EMAIL_REGEX.test(value || "");
      setFieldError(name, !isValid && EMAIL_ERROR_MESSAGE);
      return;
    }

    if (name === "secondName") {
      const isValid = NAME_FIELD_REGEX.test(value);
      setFieldError(name, !isValid && SECOND_NAME_ERROR_MESSAGE);
      return;
    }

    if (name === "firstName") {
      const isValid = NAME_FIELD_REGEX.test(value);
      setFieldError(name, !isValid && FIRST_NAME_ERROR_MESSAGE);
      return;
    }

    // valid field
    setFieldError(name, "");
  };

  const handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    setIsValid(true);
    setErrorMessage("");
    validateField(name, values[name]);
  };

  useEffect(() => {
    if (val && hasResponded) {
      setTimeout(() => {
        next();
      }, 300);
      setHasResponded(false);
    }
  }, [val, hasResponded]);

  const handleSkipClick = () => {
    setVal({
      email: "",
      firstName: "",
      phone: "",
      secondName: "",
    });

    setIsAnswered(true);
    setHasResponded(true);

    // next();
  };

  const handleClick = () => {
    setIsAnswered(true);
    setHasResponded(true);

    if (values) {
      const areValid = isFormAnswered();

      setIsAnswered(areValid);
      setVal(areValid ? values : undefined);
    }
  };

  return (
    <div>
      <ContactsFieldsDisplay
        values={values}
        error={error}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        id={id}
        isCpaSkipFunnel
      />
      <div className="renderer-core-field-action is-visible mt-[28px]">
        {/* custom next button */}
        <button
          onClick={handleClick}
          tabIndex={0}
          className={classNames(
            "renderer-core-button css-lgveel",
            !isFormAnswered() && " !cursor-default hover:shadow-none"
          )}
          style={{
            backgroundColor: !isFormAnswered() ? "#e4e4e4" : undefined,
          }}
          role="presentation"
        >
          <div className="renderer-core-html-parser">
            <p>Next</p>
          </div>
        </button>
      </div>

      <div className="flex justify-center ">
        <button
          onClick={handleSkipClick}
          className="mx-auto underline text-base text-gray"
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default ContactsCpaCustomBlock;
