import { BlockConfiguration } from "../../../types";
import AddressCustomBlock from "../../../containers/QuizCustomBlocks/AddressBlock";

export const addressFieldsConfig: BlockConfiguration = {
  name: "shipping-fields-checkout",
  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
    },
    isAnswered: {
      type: "boolean",
    },
  },
  display: AddressCustomBlock,
};
