import advertisedOn from "./advertised-on.png";
import delivery from "./delivery.png";
import doctor from "./doctor.png";
import productImg from "./produt.png";
import HIPAA from "./hipaa-compliant.png";
import logoDefault from "./logo.svg";
import logoScript from "./logoScript.png";
import rate from "./rate.svg";
import signature from "./signature.svg";
import { IS_SCRIPT_SITE } from "../../constants";

export const logo = IS_SCRIPT_SITE ? logoScript : logoDefault;

export { advertisedOn, delivery, doctor, productImg, HIPAA, rate, signature };
