import { BlockConfiguration } from "../../../types";
import ContactsCustomBlock from "../../../containers/QuizCustomBlocks/ContactsBlock";

export const contactFieldsConfig: BlockConfiguration = {
  name: "contacts-fields",
  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
    },
    isAnswered: {
      type: "boolean",
    },
  },
  display: ContactsCustomBlock,
};
