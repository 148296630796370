import { FC } from "react";
import { Link } from "react-router-dom";
import { groupedFooterLinks, IS_SCRIPT_SITE } from "../../constants";
import InfoBanner from "../InfoBanner";

const Footer: FC = () => (
  <footer className="mt-10 flex flex-col items-center text-center bg-deep-purple pt-3.5 pb-8 px-4 lg:px-6 text-white font-openSans relative z-20">
    <div className="text-[14px] sm:text-[15px] mb-7 sm:mb-8">
      All trademarks are the property of their respective owners.
    </div>
    <div
      className={
        "mx-4 items-center gap-4 sm:gap-8 sm:flex-row flex-col mb-5 flex justify-center relative z-10 transition-[opacity] duration-500"
      }
    >
      <InfoBanner />
      <Link
        to={`https://www.legitscript.com/websites/?checker_keywords=${
          IS_SCRIPT_SITE ? "scriptderm.com" : "skinnyrx.com"
        }`}
        title="Verify LegitScript Approval"
        className="above-999:hidden"
      >
        <img
          src={`https://static.legitscript.com/seals/${
            IS_SCRIPT_SITE ? "26292779.png" : "20928997.png"
          }`}
          loading="lazy"
          alt="Legit approved"
          width="140"
          height="120"
          className="object-contain"
        />
      </Link>
      <a
        aria-label="DMCA.com Protection Status"
        href="https://www.dmca.com/Protection/Status.aspx?ID=65a7a448-3498-412c-80be-4367c40594f6"
        title="DMCA.com Protection Status"
        className="dmca-badge"
      >
        <img
          src="https://images.dmca.com/Badges/dmca-badge-w250-5x1-04.png?ID=65a7a448-3498-412c-80be-4367c40594f6"
          alt="DMCA.com Protection Status"
          loading="lazy"
          width="150"
          height="150"
          className="object-contain"
        />
      </a>
    </div>

    <div className="text-[15px] sm:text-[17px] mb-5">256-BIT TLS SECURITY</div>

    {groupedFooterLinks.map((group, i) => (
      <div
        key={i}
        className={`flex gap-4 sm:gap-5 text-[12px] sm:text-[14px] ${
          i === groupedFooterLinks.length - 1 ? "mb-0" : "mb-3.5"
        }`}
      >
        {group.map(({ name, path }) => (
          <Link
            key={name}
            to={path}
            className="hover:underline underline-offset-4 transition-all"
          >
            {name}
          </Link>
        ))}
      </div>
    ))}
  </footer>
);

export default Footer;
