interface FormSettings {
  disableProgressBar?: boolean;
  disableWheelSwiping?: boolean;
  disableNavigationArrows?: boolean;
  animationDirection?: 'vertical' | 'horizontal';
  showQuestionsNumbers?: boolean;
  showLettersOnAnswers?: boolean;
  saveAnswersInBrowser?: boolean;
  displayBranding?: boolean;
}

export const formSettings: FormSettings = {
  animationDirection: 'horizontal',
  disableWheelSwiping: true,
  disableNavigationArrows: true,
  disableProgressBar: true,
  showQuestionsNumbers: false,
  showLettersOnAnswers: false,
  saveAnswersInBrowser: false,
  displayBranding: false,
}
