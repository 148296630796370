export const statesData = [
  {
    state: "Alabama",
    abbreviation: "AL",
  },
  {
    state: "Alaska",
    abbreviation: "AK",
  },
  {
    state: "Arizona",
    abbreviation: "AZ",
  },
  {
    state: "Arkansas",
    abbreviation: "AR",
  },
  {
    state: "California",
    abbreviation: "CA",
  },
  {
    state: "Colorado",
    abbreviation: "CO",
  },
  {
    state: "Connecticut",
    abbreviation: "CT",
  },
  {
    state: "Delaware",
    abbreviation: "DE",
  },
  {
    state: "Florida",
    abbreviation: "FL",
  },
  {
    state: "Georgia",
    abbreviation: "GA",
  },
  {
    state: "Hawaii",
    abbreviation: "HI",
  },
  {
    state: "Idaho",
    abbreviation: "ID",
  },
  {
    state: "Illinois",
    abbreviation: "IL",
  },
  {
    state: "Indiana",
    abbreviation: "IN",
  },
  {
    state: "Iowa",
    abbreviation: "IA",
  },
  {
    state: "Kansas",
    abbreviation: "KS",
  },
  {
    state: "Kentucky",
    abbreviation: "KY",
  },
  {
    state: "Louisiana",
    abbreviation: "LA",
  },
  {
    state: "Maine",
    abbreviation: "ME",
  },
  {
    state: "Maryland",
    abbreviation: "MD",
  },
  {
    state: "Massachusetts",
    abbreviation: "MA",
  },
  {
    state: "Michigan",
    abbreviation: "MI",
  },
  {
    state: "Minnesota",
    abbreviation: "MN",
  },
  {
    state: "Missouri",
    abbreviation: "MO",
  },
  {
    state: "Montana",
    abbreviation: "MT",
  },
  {
    state: "Nebraska",
    abbreviation: "NE",
  },
  {
    state: "Nevada",
    abbreviation: "NV",
  },
  {
    state: "New Hampshire",
    abbreviation: "NH",
  },
  {
    state: "New Jersey",
    abbreviation: "NJ",
  },
  {
    state: "New Mexico",
    abbreviation: "NM",
  },
  {
    state: "New York",
    abbreviation: "NY",
  },
  {
    state: "North Carolina",
    abbreviation: "NC",
  },
  {
    state: "North Dakota",
    abbreviation: "ND",
  },
  {
    state: "Ohio",
    abbreviation: "OH",
  },
  {
    state: "Oklahoma",
    abbreviation: "OK",
  },
  {
    state: "Oregon",
    abbreviation: "OR",
  },
  {
    state: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    state: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    state: "Rhode Island",
    abbreviation: "RI",
  },
  {
    state: "South Carolina",
    abbreviation: "SC",
  },
  {
    state: "South Dakota",
    abbreviation: "SD",
  },
  {
    state: "Tennessee",
    abbreviation: "TN",
  },
  {
    state: "Texas",
    abbreviation: "TX",
  },
  {
    state: "Utah",
    abbreviation: "UT",
  },
  {
    state: "Vermont",
    abbreviation: "VT",
  },
  {
    state: "Virginia",
    abbreviation: "VA",
  },
  {
    state: "Washington",
    abbreviation: "WA",
  },
  {
    state: "Washington DC",
    abbreviation: "DC",
  },
  {
    state: "West Virginia",
    abbreviation: "WV",
  },
  {
    state: "Wisconsin",
    abbreviation: "WI",
  },
  {
    state: "Wyoming",
    abbreviation: "WY",
  },
  {
    state: "Armed Forces Pacific",
    abbreviation: "AP",
  },
];

export const providerStateData = [
  {
    state: "Alabama",
    abbreviation: "AL",
  },
  {
    state: "Colorado",
    abbreviation: "CO",
  },
  {
    state: "Connecticut",
    abbreviation: "CT",
  },
  {
    state: "Delaware",
    abbreviation: "DE",
  },
  {
    state: "Florida",
    abbreviation: "FL",
  },
  {
    state: "Georgia",
    abbreviation: "GA",
  },
  {
    state: "Idaho",
    abbreviation: "ID",
  },
  {
    state: "Illinois",
    abbreviation: "IL",
  },
  {
    state: "Indiana",
    abbreviation: "IN",
  },
  {
    state: "Iowa",
    abbreviation: "IA",
  },
  {
    state: "Kentucky",
    abbreviation: "KY",
  },
  {
    state: "Maine",
    abbreviation: "ME",
  },
  {
    state: "Maryland",
    abbreviation: "MD",
  },
  {
    state: "Massachusetts",
    abbreviation: "MA",
  },
  {
    state: "Michigan",
    abbreviation: "MI",
  },
  {
    state: "Minnesota",
    abbreviation: "MN",
  },
  {
    state: "Montana",
    abbreviation: "MT",
  },
  {
    state: "Nebraska",
    abbreviation: "NE",
  },
  {
    state: "Nevada",
    abbreviation: "NV",
  },
  {
    state: "New Hampshire",
    abbreviation: "NH",
  },
  {
    state: "New Jersey",
    abbreviation: "NJ",
  },
  {
    state: "New Mexico",
    abbreviation: "NM",
  },
  {
    state: "New York",
    abbreviation: "NY",
  },
  {
    state: "North Carolina",
    abbreviation: "NC",
  },
  {
    state: "North Dakota",
    abbreviation: "ND",
  },
  {
    state: "Ohio",
    abbreviation: "OH",
  },
  {
    state: "Oklahoma",
    abbreviation: "OK",
  },
  {
    state: "Oregon",
    abbreviation: "OR",
  },
  {
    state: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    state: "Rhode Island",
    abbreviation: "RI",
  },
  {
    state: "South Dakota",
    abbreviation: "SD",
  },
  {
    state: "Texas",
    abbreviation: "TX",
  },
  {
    state: "Utah",
    abbreviation: "UT",
  },
  {
    state: "Vermont",
    abbreviation: "VT",
  },
  {
    state: "Virginia",
    abbreviation: "VA",
  },
  {
    state: "Washington",
    abbreviation: "WA",
  },
  {
    state: "Washington DC",
    abbreviation: "DC",
  },
  {
    state: "Wisconsin",
    abbreviation: "WI",
  },
  {
    state: "Wyoming",
    abbreviation: "WY",
  },
  {
    state: "Other Stare",
    abbreviation: "other",
  },
];

export interface IAddressAutocompleteFields {
  address: string;
  city?: string;
  state?: string;
  zipCode?: string;
  apartment?: string;
  suite?: string;
}

export const availableStatesAbbreviations = statesData.map(
  ({ abbreviation }) => abbreviation
);

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

export const fetchAutocompletePlaceData = async (placeId: string) => {
  const placeDetails: Omit<IAddressAutocompleteFields, "address"> = {};

  try {
    const response = await fetch(
      `https://places.googleapis.com/v1/places/${placeId}?fields=address_components&key=${MAPS_API_KEY}`
    );

    if (!response.ok) {
      throw new Error("Api error");
    }

    const data = await response.json();

    const addressComponents = data.addressComponents;

    addressComponents.forEach((component: any) => {
      switch (component.types[0]) {
        case "locality":
        case "administrative_area_level_3":
        case "sublocality_level_1":
          placeDetails.city = component.longText;
          break;
        case "administrative_area_level_1":
          placeDetails.state = component.shortText;
          break;
        case "postal_code":
          placeDetails.zipCode = component.longText;
          break;
        case "subpremise":
          placeDetails.apartment = component.longText;
          break;
        case "premise":
          placeDetails.suite = component.longText;
          break;
        default:
          break;
      }
    });

    return placeDetails;
  } catch (error) {
    console.error("Error:", error);
  }
};
