import {LuLoader2} from 'react-icons/lu'
import {FC} from 'react'

interface FullScreenLoaderProps {
  isLoading: boolean;
}

const FullScreenLoader: FC<FullScreenLoaderProps> = ({isLoading}) => {
  return (
    <div
      className={`
    fixed inset-0 flex items-center justify-center bg-white transition-opacity duration-300
    ${isLoading ? 'opacity-100 z-50' : 'opacity-0 z-0'}
    `}
    >
      <LuLoader2 className='w-14 h-14 animate-spin'/>
    </div>
  )
}

export default FullScreenLoader
