import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { CookiesKey, QuizAffiliateProvider } from "../../types/cookies";

const CpaTracking = () => {
  const isPassedCpa = Cookies.get(CookiesKey.PASSED_CPA_QUIZ);

  if (!isPassedCpa) {
    return <></>;
  }

  const cpaAffiliateProvider = Cookies.get(
    CookiesKey.QUIZ_AFFILIATE_CMP
  ) as QuizAffiliateProvider;
  const transactionId = Cookies.get(
    CookiesKey.QUIZ_AFFILIATE_ID
  ) as QuizAffiliateProvider;

  return (
    <>
      <Helmet defer>
        {cpaAffiliateProvider === QuizAffiliateProvider.TO_CPA && (
          <script>
            {`
                ;!function(s,c,a,l,e){function r(t){return btoa(encodeURIComponent(t))}function i(t){i.l?i[t]&&i[t
                ].apply(i,arguments):i.q.push(arguments)}var t=l.split("."),d=s.location,s=(i.v="1.0.0",i.q=[],i.s="",
                i.config=function(t,n){i.s=n},i.track=function(t,n,o){var r=new XMLHttpRequest;
                r.onreadystatechange=function(){var t;4==this.readyState&&((t=c.createElement("iframe")
                ).height=t.width=0,t.style="display:none;visibility:hidden;",
                t.sandbox="allow-forms allow-scripts allow-same-origin",t.srcdoc=this.responseText,(c.body||c.head
                ).appendChild(t),setTimeout(function(){return t.remove()},3e4))},r.open("GET",e+i.state(!1,n,o||{}),!0),
                r.send()},i.state=function(t,n,o){return"?v="+i.v+"&h="+l+"&u="+r(d.href)+"&r="+r(c.referrer)+(
                n?"&s="+i.s+"&i="+(c.cookie.match(RegExp(decodeURIComponent("%5Cb")+l+"=([^;]+)"))||[])[1
                ]+"&e="+n+"&c="+r(JSON.stringify(o)):"")},s[t[0]]=s[t[0]]||{});s.io||(s.io=i,(t=c.createElement(a)
                ).async=!0,t.src=e+"sdk.js"+i.state(),c.addEventListener("beforeunload",t.onerror=function(){i.l=!0;for(
                var t=0;t<i.q.length;++t)i.apply(i,i.q[t]);i.q=[]}),(s=c.getElementsByTagName(a)[0]
                ).parentNode.insertBefore(t,s))}(window,document,"script","theoffer.io","https://api.secureoffer.io/");


                // const orderId = crypto.randomUUID();
                theoffer.io("config", "endpUpLe4TqhZT7l7c8tz");
                // user id taken from url
                theoffer.io("track", "Purchase", { orderId: window.location.pathname.replace("/", "") });

                `}
          </script>
        )}

        <script>
          {`
          try {
            fbq('init', '436103438892412');

            fbq("trackSingle", "436103438892412", "Purchase", {value: 0.00, currency: 'USD'});

          } catch(e) {
            console.error(e)
          }
          `}
        </script>
        {cpaAffiliateProvider === QuizAffiliateProvider.CANDY_CPA && (
          <script>
            {`
          !function(d,s) {
          var rc = "//go.referralcandy.com/purchase/l9aq8rm3kgn28jkrkjdfg819c.js";
          var js = d.createElement(s);
          js.src = rc;
          var fjs = d.getElementsByTagName(s)[0];
          fjs.parentNode.insertBefore(js,fjs);
          }(document,"script");
          `}
          </script>
        )}
      </Helmet>

      {cpaAffiliateProvider === QuizAffiliateProvider.BW_CPA &&
        transactionId && (
          <iframe
            src={`https://www.b2cgtxtrk.com/?nid=1488&transaction_id=${transactionId}`}
            height="1"
            width="1"
            title=" "
          ></iframe>
        )}
    </>
  );
};

export { CpaTracking };
