import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { QuizBlockProps } from "../../types";

import { useQuiz } from "../../contexts/QuizContext";
import AddressFieldsDisplay from "../../components/QuizForm/CustomBlocks/AddressDisplay";
import { saveUserInfoValues } from "../../utils/userInfoData";

const NUMBER_OF_ANSWERS = 4;

const DEFAULT_ERROR_MESSAGE = "Required";

const AddressCustomBlock = ({
  val,
  setVal,
  id,
  setIsAnswered,
}: QuizBlockProps) => {
  const [values, setValues] = useState(val || {});
  const [error, setError] = useState<Record<string, string | boolean>>({});

  useEffect(() => {
    saveUserInfoValues(values);
  }, [values]);

  const { setIsValid, setErrorMessage } = useQuiz();

  const isFormAnswered = () => {
    const requiredValues = { ...values };
    delete requiredValues["address2"];

    const isAllAnswered =
      Object.values(requiredValues).filter(Boolean).length ===
      NUMBER_OF_ANSWERS;

    const isNoErrors = Object.values(error).every(
      (errorMessage) => !errorMessage
    );

    return isAllAnswered && isNoErrors;
  };

  useEffect(() => {
    if (values) {
      const areValid = isFormAnswered();

      setIsAnswered(areValid);
      setIsValid(areValid);

      setVal(areValid ? values : undefined);
    }
  }, [values, error]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    handleValueChange(name, value);
  };

  const handleValueChange = (name: string, value: string) => {
    setValues((values: any) => ({ ...values, [name]: value }));

    if (error[name]) {
      validateField(name, value);
    }
  };

  const setFieldError = (field: string, errorMessage: string | boolean) => {
    setError((errors) => ({ ...errors, [field]: errorMessage }));
  };

  const validateField = (name: string, value: string) => {
    if (name === "address2") return setFieldError(name, "");

    if (!value) {
      setFieldError(name, DEFAULT_ERROR_MESSAGE);
      return;
    }

    // no special validation for first and second names

    setFieldError(name, "");
  };

  const handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    setErrorMessage("");
    validateField(name, values[name]);
  };

  return (
    <div>
      <AddressFieldsDisplay
        values={values}
        error={error}
        triggerValueValidation={validateField}
        onValueChange={handleValueChange}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        id={id}
      />
    </div>
  );
};

export default AddressCustomBlock;
