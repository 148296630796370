import {FC, useCallback, useRef, useState} from 'react'
import Webcam from 'react-webcam'
import Modal from 'react-modal'
import {MdClose, MdOutlinePhotoCamera} from 'react-icons/md'
import toast from 'react-hot-toast'
import {checkBrowserMediaSupport, checkCameraAccess, getFileName, getMimeType, MIME_TYPES} from '../../utils'
import {videoConstraints} from '../../constants'

Modal.setAppElement('#root')

interface CustomWebcamProps {
  type: 'video' | 'image';

  onChange: (value: File[], action: 'add' | 'remove') => void;
}

const CustomWebcam: FC<CustomWebcamProps> = ({type, onChange}) => {
  const webcamRef = useRef<Webcam | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [recording, setRecording] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([])

  const openModal = async () => {
    const cameraAccess = await checkCameraAccess()
    const browserMediaSupport = checkBrowserMediaSupport()

    if (cameraAccess && browserMediaSupport) {
      setIsOpen(true)
    }
  }

  const stopMediaStreamTracks = () => {
    if (mediaRecorderRef.current instanceof MediaRecorder) {
      mediaRecorderRef.current.stop()

      mediaRecorderRef.current.onstop = () => {
        if (webcamRef.current?.stream) {
          const tracks = webcamRef.current?.stream.getTracks()
          tracks.forEach(track => track.stop())
        }
      }
    }
  }

  const closeModal = () => {
    stopMediaStreamTracks()
    if (recording) setRecording(false)
    setRecordedChunks([])
    setIsOpen(false)
  }

  const capturePhoto = useCallback(
    () => {
      const imageSrc = webcamRef.current?.getScreenshot()
      if (imageSrc) {
        fetch(imageSrc)
          .then(res => res.blob())
          .then(blob => new File([blob], getFileName(type, MIME_TYPES.IMAGE_JPEG), {type: MIME_TYPES.IMAGE_JPEG}))
          .then(file => {
            onChange([file], 'add')
          })
          .catch(error => toast.error('Error capturing the photo:', error))
        setIsOpen(false)
      }
    }, [webcamRef, onChange],
  )

  const handleDataAvailable = (e: BlobEvent) => {
    if (e.data.size > 0) {
      setRecordedChunks((prev) => [...prev, e.data])
    }
  }

  const startRecording = () => {
    if (webcamRef.current instanceof Webcam && webcamRef.current?.stream) {
      setRecording(true)

      try {
        const mimeType = getMimeType()

        mediaRecorderRef.current = new MediaRecorder(
          webcamRef.current.stream,
          {mimeType},
        )

        if ('ondataavailable' in mediaRecorderRef.current) {
          mediaRecorderRef.current.ondataavailable = handleDataAvailable
        }

        mediaRecorderRef.current?.start()
      } catch (error) {
        toast.error(`Failed to start recording: ${error}`)
        setRecording(false)
      }
    }
  }

  const stopRecording = () => {
    stopMediaStreamTracks()

    const mimeType = getMimeType()
    const blob = new Blob(recordedChunks, {type: mimeType})
    const videoFile = new File([blob], getFileName(type, mimeType), {type: mimeType})
    onChange([videoFile], 'add')
    const videoUrl = URL.createObjectURL(blob)

    setRecording(false)
    setIsOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel='Webcam Capture'
        className='fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[1000]'
      >
        <div className='relative p-8 bg-white rounded-lg shadow-lg max-w-lg w-full flex justify-center'>
          <button
            onClick={closeModal}
            className='absolute top-0 right-0 m-2 text-xl hover:text-gray-600'
          >
            <MdClose/>
          </button>

          <Webcam
            ref={webcamRef}
            audio={type === 'video'}
            screenshotFormat='image/jpeg'
            imageSmoothing={true}
            videoConstraints={videoConstraints}
            className='w-full h-auto'
          />
          {type === 'image' && (
            <button
              className='w-10 h-10 absolute bottom-14 z-50 mx-auto bg-white rounded-full outline outline-white outline-offset-2 outline-4 cursor-pointer'
              onClick={capturePhoto}
            />
          )}
          {type === 'video' && (
            <button
              className={`
                w-10 h-10 absolute bottom-14 z-50 mx-auto bg-red outline outline-white outline-offset-2 outline-4 cursor-pointer
                ${recording ? 'rounded-md' : 'rounded-full'}
                `}
              onClick={recording ? stopRecording : startRecording}
            />
          )}
        </div>
      </Modal>

      <button className='dropzone-area' onClick={openModal}>
        <div className='text-silver'>
          <MdOutlinePhotoCamera className='icon-size'/>
        </div>
        <p className='text-sm sm:text-base lg:text-lg text-silver text-center'>
          Take from camera
        </p>
      </button>
    </>
  )
}

export default CustomWebcam
