import { ChangeEvent, FC, SyntheticEvent } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import { InputField } from "../../common/input";
import { AddressAutocompleteInput } from "../../common/addressInput";
import {
  IAddressAutocompleteFields,
  statesData,
} from "../../common/addressInput/common";
import { SelectField } from "../../common/select";

export const stateOptions = statesData.map((state) => ({
  value: state.abbreviation,
  label: state.state,
}));

interface InputFieldDisplayProps {
  id: string;
  values: Record<string, string>;
  triggerValueValidation: (name: string, value: string) => void;
  onValueChange: (name: string, value: string) => void;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: SyntheticEvent<HTMLInputElement>) => void;
  error: Record<string, string | boolean>;
  isCpaFunnel?: boolean;
}

const AddressFieldsDisplay: FC<InputFieldDisplayProps> = ({
  id,
  values,
  error,
  onValueChange,
  onInputChange,
  onBlur,
  triggerValueValidation,
}) => {
  const handleAddressInput = async (
    _: string,
    addressData: IAddressAutocompleteFields
  ) => {
    Object.entries(addressData).forEach(async ([key, value]) => {
      if (["city", "state", "zipCode", "address", "address2"].includes(key)) {
        onValueChange(key, value);

        triggerValueValidation(key, value);
      }
    });
  };

  return (
    <QuizBlockLayout currentBlockId={id}>
      <div className=" flex flex-col-reverse items-center	 w-full justify-center gap-4 desktop:p-8">
        <div className="text-center max-w-[100%] w-[480px] flex-col flex gap-2">
          <h4
            color="red"
            className="text-center uppercase section-title xs:text-2xl text-xl"
          >
            Shipping Address
          </h4>
          <div>
            <p className="section-title !text-[17px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-5 h-5 inline text-purple/60 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                ></path>
              </svg>
              If approved we need your address to ship your medication.
            </p>
          </div>
          <AddressAutocompleteInput
            addressValue={values["address"]}
            onChange={handleAddressInput}
          />

          <InputField
            value={values["address2"]}
            error={error["address2"]}
            name="address2"
            onChange={onInputChange}
            onBlur={onBlur}
            label="Address line 2 - optional"
            required
          />

          <div className="flex gap-3">
            <InputField
              value={values["city"]}
              error={error["city"]}
              name="city"
              onChange={onInputChange}
              onBlur={onBlur}
              label="City"
              required
            />
            <InputField
              value={values["zipCode"]}
              error={error["zipCode"]}
              name="zipCode"
              onChange={onInputChange}
              onBlur={onBlur}
              label="Zip Code"
              required
            />
            <SelectField
              options={stateOptions}
              value={values["state"]}
              error={error["state"]}
              name="state"
              onChange={onInputChange as any}
              onBlur={onBlur as any}
              label="State"
              required
            />
          </div>
        </div>
      </div>
    </QuizBlockLayout>
  );
};

export default AddressFieldsDisplay;
