import dayjs from "dayjs";

export const validateDob = (dob: string) => {
  if (dob.length < 10) {
    return "Date of birth must be at least 10 characters long.";
  }

  const date = dayjs(dob, "MM/DD/YYYY", true);
  const today = dayjs();

  if (!date.isValid() || !date.isBefore(today)) {
    return "Invalid date of birth. It should be a valid date before today in MM/DD/YYYY format.";
  }

  if (dayjs().diff(date, "year", true) > 65) {
    return "Sorry, we can't qualify patients over 65 years old.";
  }

  return "";
};
