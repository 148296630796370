import {BlockConfiguration} from '../../../types'
import SelectSexBlock from '../../../containers/QuizCustomBlocks/SelectSexBlock'

export const selectSexConfig: BlockConfiguration = {
  name: 'select-sex',
  supports: {
    editable: true,
  },
  attributes: {
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    classnames: {
      type: 'string',
      'default': 'without-footer-button',
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
    options: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          value: {
            type: 'string',
          },
          label: {
            type: 'string',
          },
        },
      },
    },
    conditionalBlocks: {
      type: 'object',
      additionalProperties: {
        type: 'array',
        items: {
          type: 'object',
        },
      },
    },
  },
  display: SelectSexBlock,
}
