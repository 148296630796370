import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import ContactsFieldsDisplay from "../../components/QuizForm/CustomBlocks/ContactsDisplay";
import { QuizBlockProps } from "../../types";
import {
  EMAIL_REGEX,
  EMAIL_TAKEN_ERROR_MESSAGE,
  PHONE_REGEX,
  PHONE_TAKEN_ERROR_MESSAGE,
  VERIFY_CODE_REGEX,
} from "../../constants";
import { useQuiz } from "../../contexts/QuizContext";
import { verifyContacts } from "../../api/quiz";
import { useCurrentBlock } from "@quillforms/renderer-core";
import ContactsVerifyDisplay from "../../components/QuizForm/CustomBlocks/ContactsVerifyDisplay";
import classNames from "classnames";

const NUMBER_OF_ANSWERS = 5;

const DEFAULT_ERROR_MESSAGE = "Required";
const EMAIL_ERROR_MESSAGE = "Enter valid email";
const VERIFY_CODE_ERROR_MESSAGE =
  "Invalid code. It should be in the format of XX-XX ";

const PHONE_ERROR_MESSAGE =
  "Invalid phone number format. It should be in the format (123) 456-7890";

const ContactsCustomBlock = ({
  val,
  setVal,
  id,
  setIsAnswered,
}: QuizBlockProps) => {
  const [values, setValues] = useState(val || {});
  const [error, setError] = useState<Record<string, string | boolean>>({});

  const [isLoadingInitialVerify, setLoadingInitialVerify] = useState(false);
  const [isVerifyStep, setIsVerifyStep] = useState(false);
  const { setIsValid, setErrorMessage } = useQuiz();

  const isFormAnswered = () => {
    const isAllAnswered =
      Object.values(values).filter(Boolean).length === NUMBER_OF_ANSWERS;

    const isNoErrors = Object.values(error).every(
      (errorMessage) => !errorMessage
    );

    return isAllAnswered && isNoErrors;
  };

  const isAllFilledBeforeVerify = () => {
    // exclude verify code from check list
    const isAllAnswered =
      Object.entries(values)
        .filter(([key]) => key !== "verificationCode")
        .map(([_, value]) => value)
        .filter(Boolean).length ===
      NUMBER_OF_ANSWERS - 1;

    const isNoErrors = Object.entries(error)
      .filter(([key]) => key !== "verificationCode")
      .map(([_, value]) => value)
      .every((errorMessage) => !errorMessage);

    return isAllAnswered && isNoErrors;
  };

  const bodyRef = useRef<HTMLBodyElement | null>();

  const currentBlock = useCurrentBlock();
  const currentBlockId = currentBlock?.id;

  useEffect(() => {
    if (currentBlockId !== id) {
      setIsVerifyStep(false);
      bodyRef.current?.classList.remove("without-footer-button");
    }
  }, [currentBlockId, id]);

  useEffect(() => {
    if (!bodyRef.current) {
      bodyRef.current = document.getElementsByTagName("body")[0];
    }

    if (!isVerifyStep && currentBlockId === id) {
      bodyRef.current?.classList.add("without-footer-button");
    } else {
      bodyRef.current?.classList.remove("without-footer-button");
    }

    return () => bodyRef.current?.classList.remove("without-footer-button");
  }, [isVerifyStep, currentBlockId]);

  useEffect(() => {
    if (values) {
      const areValid = isFormAnswered();

      setIsAnswered(areValid);
      setVal(areValid ? values : undefined);
    }
  }, [values, error]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });

    if (error[name]) {
      validateField(name, value);
    }
  };

  const setFieldError = (field: string, errorMessage: string | boolean) => {
    setError((errors) => ({ ...errors, [field]: errorMessage }));
  };

  const validateField = (name: string, value: string) => {
    if (!value) {
      setFieldError(name, DEFAULT_ERROR_MESSAGE);
      return;
    }

    // no special validation for first and second names
    if (name === "phone") {
      const isValid = PHONE_REGEX.test(value || "");
      setFieldError(name, !isValid && PHONE_ERROR_MESSAGE);
      return;
    }

    if (name === "email") {
      const isValid = EMAIL_REGEX.test(value || "");
      setFieldError(name, !isValid && EMAIL_ERROR_MESSAGE);
      return;
    }

    if (name === "verificationCode") {
      const isValid = VERIFY_CODE_REGEX.test(value || "");
      setFieldError(name, !isValid && VERIFY_CODE_ERROR_MESSAGE);
      return;
    }

    // valid field
    setFieldError(name, "");
  };

  const handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    setIsValid(true);
    setErrorMessage("");
    validateField(name, values[name]);
  };

  const initialVerify = async () =>
    await verifyContacts({
      email: values["email"],
      phone_number: values["phone"],
    });

  const handleNextClick = async () => {
    try {
      setErrorMessage("");

      if (isLoadingInitialVerify || !isAllFilledBeforeVerify()) {
        return;
      }

      setLoadingInitialVerify(true);
      const failedField = await initialVerify();

      setLoadingInitialVerify(false);

      if (failedField === "email") {
        setFieldError("email", EMAIL_TAKEN_ERROR_MESSAGE);
        return;
      }
      if (failedField === "phone") {
        setFieldError("phone", PHONE_TAKEN_ERROR_MESSAGE);
        return;
      }

      setIsVerifyStep(true);
    } catch (e) {
      setErrorMessage("Unknown error occurred");
    } finally {
      setLoadingInitialVerify(false);
    }
  };

  if (isVerifyStep) {
    return (
      <ContactsVerifyDisplay
        values={values}
        error={error}
        id={id}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        onBackPressed={() => setIsVerifyStep(false)}
        onResendCode={initialVerify}
      />
    );
  }

  return (
    <div>
      <ContactsFieldsDisplay
        values={values}
        error={error}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        id={id}
      />
      <div className="renderer-core-field-action is-visible mt-[28px]">
        {/* custom next button */}
        <button
          onClick={handleNextClick}
          tabIndex={0}
          className={classNames(
            "renderer-core-button css-lgveel",
            !isAllFilledBeforeVerify() && " !cursor-default hover:shadow-none"
          )}
          style={{
            backgroundColor: !isAllFilledBeforeVerify() ? "#e4e4e4" : undefined,
          }}
          role="presentation"
        >
          <div className="renderer-core-html-parser">
            <p>Next</p>{" "}
          </div>
        </button>
      </div>
    </div>
  );
};

export default ContactsCustomBlock;
