import {FC} from 'react'
import PageLayout from '../layouts/PageLayout'
import ErrorScreenDisplay from '../components/QuizForm/CustomBlocks/ErrorScreenDisplay'
import {DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE} from '../constants'

interface ErrorPageProps {
  title?: string;
  message?: string;
}

const ErrorPage: FC<ErrorPageProps> = ({
  title = DEFAULT_ERROR_TITLE,
  message = DEFAULT_ERROR_MESSAGE,
}) => {
  return (
    <PageLayout loading={false}>
      <div className='-mt-24 sm:-mt-38 lg:-mt-40 mb-4 sm:mb-5 lg:mb-6 mx-4 xs:mx-8 lg:mx-6 flex justify-center'>
        <div
          className='w-full sm:w-618 bg-white rounded-xl drop-shadow-[0_3px_4px_rgba(0,0,0,0.25)] px-[74px] pt-12 pb-10 block-padding'
        >
          <ErrorScreenDisplay
            title={title}
            message={message}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export default ErrorPage
