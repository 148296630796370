import {FC} from 'react'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'
import {icons} from '../../../containers/QuizCustomBlocks/constants/SelectSexBlock.constants'
import classNames from 'classnames'

interface SelectSexDisplayProps {
  id: string;
  question: string;
  options: { value: string, label: string }[];
  prompt?: string;
  value: string | undefined;
  onClick: (value: string) => void;
}

const SelectSexDisplay: FC<SelectSexDisplayProps> = ({
  id,
  question,
  prompt,
  options,
  value: currentValue,
  onClick,
}) => {
  return (
    <QuizBlockLayout
      currentBlockId={id}
      question={question}
      prompt={prompt}
    >
      <div className='flex gap-2.5 sm:gap-3 lg:gap-4'>
        {options.map(({label, value}) => (
          <div
            key={label}
            className={classNames(
              'group interactive-card', {
                '!border-purple-2': currentValue === value,
              },
            )}
            onClick={() => onClick(value)}
          >
            <div
              className={classNames(
                'mb-2 sm:mb-3 lg:mb-4 h-20 sm:h-28 text-dark-purple group-hover:text-purple-2', {
                  'text-purple-2': currentValue === value,
                },
              )}
            >
              {icons[value]}
            </div>
            <p
              className={classNames(
                'text-base sm:text-lg lg:text-xl text-charcoal-gray font-bold transition ease-in duration-300 group-hover:text-purple-2', {
                  'text-purple-2': currentValue === value,
                },
              )}
            >
              {label}
            </p>
          </div>
        ))}
      </div>
    </QuizBlockLayout>
  )
}

export default SelectSexDisplay
