import React, { useState, FC, useRef, useEffect } from "react";
import clsx from "clsx";

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  body: React.ReactNode;
}

export const Accordion: FC<AccordionProps> = ({
  title,
  body,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<string>("0px");

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(`${contentRef.current?.scrollHeight}px`);
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  return (
    <div
      className={clsx(
        "text-xl lg:text-2xl border rounded-lg border-gray-300 font-charter text-dark-purple",
        className
      )}
      {...props}
    >
      <div
        className="flex items-center justify-between p-4 cursor-pointer border-gray-300"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <span
            className={`text-xl font-semibold mr-2 transition-transform ease-in-out duration-300 ${
              isOpen ? "rotate-45" : ""
            }`}
          >
            +
          </span>
          <h3 className="text-lg font-semibold">{title}</h3>
        </div>
      </div>
      <div
        ref={contentRef}
        className="quiz-description overflow-hidden transition-max-height duration-300 ease-in-out"
        style={{ maxHeight }}
      >
        <div
          className={clsx("p-4 border-gray-300 text-[15px]", {
            "border-t": isOpen,
          })}
        >
          {body}
        </div>
      </div>
    </div>
  );
};
