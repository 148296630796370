import { customWelcomeScreenConfig } from "./customWelcomeScreenConfig";
import { customInputFieldConfig } from "./customInputFieldConfig";
import { selectSexConfig } from "./selectSexConfig";
import { heightWeightInputFieldsConfig } from "./heightWeightInputFieldsConfig";
import { heightWeightInputFieldsConfigBasic } from "./heightWeightInputFieldsConfigBasic";
import { customMultipleChoiceConfig } from "./customMultipleChoiceConfig";
import { customGoodbyeScreenConfig } from "./customGoodbyeScreenConfig";
import { customSingleChoiceConfig } from "./customSingleChoiceConfig";
import { customAttachmentConfig } from "./customAttachmentConfig";
import { taskStatusListConfig } from "./taskStatusListConfig";
import { discountBlockConfig } from "./discountBlockConfig";
import { customLongTextConfig } from "./customLongTextConfig";
import { errorScreenConfig } from "./errorScreenConfig";
import { contactFieldsConfig } from "./customContactsFieldsConfig";
import { beforeAfterCarouselConfig } from "./carouselConfig";
import { benefitsCompareConfig } from "./benefitsCompareConfig";
import { approvedBannerConfig } from "./approvedBannerConfig";
import { doctorPrescriptionBannerConfig } from "./doctorPrescriptionBannerConfig";
import { progressCalculationConfig } from "./resultCalculation";
import { contactFieldsCpaConfig } from "./customContactCpaFieldsConfig";
import { addressFieldsConfig } from "./customAddressFieldsConfig";
import { contactFieldsCheckoutConfig } from "./customContactCheckoutFields";
import { disclaimersConfig } from "./disclaimersConfig";
import { contactFieldsCpaSkipConfig } from "./customContactCpaSkippableConfig";

export const blocksConfig = [
  customWelcomeScreenConfig,
  customInputFieldConfig,
  selectSexConfig,
  heightWeightInputFieldsConfig,
  heightWeightInputFieldsConfigBasic,
  customMultipleChoiceConfig,
  customSingleChoiceConfig,
  customAttachmentConfig,
  customGoodbyeScreenConfig,
  taskStatusListConfig,
  discountBlockConfig,
  customLongTextConfig,
  errorScreenConfig,
  contactFieldsConfig,
  beforeAfterCarouselConfig,
  benefitsCompareConfig,
  approvedBannerConfig,
  doctorPrescriptionBannerConfig,
  progressCalculationConfig,
  contactFieldsCpaConfig,
  addressFieldsConfig,
  contactFieldsCheckoutConfig,
  contactFieldsCpaSkipConfig,
  disclaimersConfig,
];
