import {FC, useCallback, useEffect, useState} from 'react'
import InputFieldDisplay from '../../components/QuizForm/CustomBlocks/InputFieldDisplay'
import {EMAIL_REGEX, FORMAT_DATE_MM_DD, FORMAT_DATE_MM_DD_YYYY, ONLY_NUMBERS} from '../../constants'
import {BaseAttributes, QuizBlockProps} from '../../types'

interface InputFieldBlockAttributes extends BaseAttributes {
  type: 'text' | 'date' | 'pressure_measurements' | 'email';
  required: boolean;
  inputType?: string;
  placeholder?: string;
  maxLength?: number;
  unit?: string;
}

interface InputFieldBlockProps extends QuizBlockProps<InputFieldBlockAttributes> {
  val: { answer: string } | undefined;
}

const DATE_LENGTH = 10
const PRESSURE_MEASUREMENTS_MIN_LENGTH = 6
const PRESSURE_MEASUREMENTS_MAX_LENGTH = 7
export const PLACEHOLDER_VALUE = 'placeholder'

const InputFieldBlock: FC<InputFieldBlockProps> = ({val, setVal, attributes, id, setIsAnswered}) => {
  const {
    question,
    prompt,
    inputType = 'text',
    placeholder,
    maxLength = 100,
    unit,
    type,
    required,
  } = attributes

  const [value, setValue] = useState(val?.answer)

  useEffect(() => {
    if (!value) {
      setIsAnswered(!required)
      setVal(required ? '' : {answer: ''})
      return
    }

    let isAnswered
    switch (type) {
      case 'date':
        isAnswered = value.length === DATE_LENGTH
        break
      case 'pressure_measurements':
        isAnswered = (value.length || 0) >= PRESSURE_MEASUREMENTS_MIN_LENGTH
        break
      case 'email':
        isAnswered = EMAIL_REGEX.test(value || '')
        break
      default:
        isAnswered = !!value
    }

    setIsAnswered(isAnswered)
    setVal(isAnswered ? {answer: value} : '')
  }, [value])

  const handleDateChange = (value: string) => {
    let cleanValue = value.replace(ONLY_NUMBERS, '')

    if (cleanValue.length > 2 && cleanValue.length <= 4) {
      cleanValue = cleanValue.replace(FORMAT_DATE_MM_DD, '$1/$2')
    }

    if (cleanValue.length > 4) {
      cleanValue = cleanValue.replace(FORMAT_DATE_MM_DD_YYYY, '$1/$2/$3')
    }

    setValue(cleanValue.slice(0, DATE_LENGTH))
  }

  const handlePressureMeasurementsChange = (value: string) => {
    let cleanValue = value.replace(ONLY_NUMBERS, '')

    if (cleanValue.length > 3) {
      cleanValue = cleanValue.slice(0, 3) + '/' + cleanValue.slice(3)
    }

    setValue(cleanValue.slice(0, PRESSURE_MEASUREMENTS_MAX_LENGTH))
  }

  const handleChange = useCallback((value: string) => {
    switch (type) {
      case 'date':
        return handleDateChange(value)
      case 'pressure_measurements':
        return handlePressureMeasurementsChange(value)
      default:
        return setValue(value)
    }
  }, [type])

  return (
    <InputFieldDisplay
      id={id}
      value={value}
      question={question}
      prompt={prompt}
      inputType={inputType}
      placeholder={placeholder}
      maxLength={maxLength}
      unit={unit}
      onChange={handleChange}
    />
  )
}

export default InputFieldBlock
