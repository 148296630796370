import {FC, useCallback, useState} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'
import {IoClose, IoImagesOutline, IoVideocamOutline} from 'react-icons/io5'
import {BsPaperclip} from 'react-icons/bs'
import toast from 'react-hot-toast'
import heic2any from 'heic2any'
import {LuLoader2} from 'react-icons/lu'
import classNames from 'classnames'
import {BYTES_PER_MB, DEFAULT_MAX_FILES} from '../../constants'

interface DropzoneUploaderProps {
  onChange: (value: File[], action: 'add' | 'remove') => void;
  value: File[] | undefined;
  type: 'video' | 'image';
  maxFileSizeMB: number;
  maxFiles: number;
}

const placeholders = {
  video: 'Choose a video from gallery',
  image: 'Choose an image from gallery',
}

const icons = {
  video: <IoVideocamOutline className='icon-size'/>,
  image: <IoImagesOutline className='icon-size'/>,
}

const accept = {
  image: {
    'image/*': [],
    'image/heic': ['.heic'],
    'image/heif': ['.heif'],
  },
  video: {
    'video/*': [],
  },
}

const DropzoneUploader: FC<DropzoneUploaderProps> = ({
  value = [],
  type,
  maxFiles,
  maxFileSizeMB,
  onChange,
}) => {
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections[0]) {
        const errorMessage = fileRejections[0].errors?.[0]?.message || 'Some error'
        toast.error(errorMessage)
        return
      }

      setLoading(true)
      const convertedFiles = await Promise.all(acceptedFiles.map(async (file) => {
        const ext = file.name.split('.').pop()?.toLowerCase()

        if (ext === 'heic' || ext === 'heif') {
          // Convert HEIC/HEIF file to JPEG
          try {
            const outputBlob = await heic2any({
              blob: file,
              toType: 'image/jpeg',
              quality: 0.8,
            }) as Blob

            // Replace the source file with a new Blob with ".jpg" extension
            return new File([outputBlob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), {
              type: 'image/jpeg',
              lastModified: file.lastModified,
            })
          } catch (error) {
            console.error('Error converting image:', error)
            return file
          }
        } else {
          return file
        }
      }))

      const newFiles = convertedFiles.filter(file => {
        const fileSizeMB = file.size / BYTES_PER_MB
        if (fileSizeMB > maxFileSizeMB) {
          toast.error(`${file.name} exceeds the size limit of ${maxFileSizeMB}MB`)
          return false
        }
        return true
      })

      if (value.length + newFiles.length > maxFiles) {
        toast.error(`Maximum ${maxFiles} files can be uploaded`)
      } else {
        onChange(newFiles, 'add')
      }
      setLoading(false)
    },
    [value, maxFiles, maxFileSizeMB, onChange],
  )

  const {getRootProps, getInputProps} = useDropzone({onDrop, maxFiles, accept: accept[type]})

  return (
    <div className='flex flex-col gap-4'>
      <div
        className={classNames(
          'h-20 sm:h-28 lg:h-36 w-full rounded-md flex items-center', {
            'border border-dashed border-silver': loading,
          },
        )}
      >
        {loading ? <LuLoader2 className='w-7 h-7 mx-auto text-blue animate-spin'/> : (
          <div {...getRootProps({className: 'dropzone dropzone-area'})}>
            <input {...getInputProps()} />
            <div className='text-silver'>
              {maxFiles > DEFAULT_MAX_FILES
                ? <BsPaperclip className='icon-size'/>
                : icons[type]
              }
            </div>
            <p className='text-sm sm:text-base lg:text-lg text-silver text-center'>
              {maxFiles > DEFAULT_MAX_FILES
                ? 'Select multiple files or drag and drop them here'
                : placeholders[type]
              }
            </p>
          </div>
        )}
      </div>

      <div>
        <ul className='flex flex-col gap-3'>
          {value.map((file, i) => (
            <li key={i} className='flex gap-2 items-center rounded-full bg-light-gray-2 px-3 py-1 max-w-56 w-fit'>
              <div className='text-base text-gray-dark-2 leading-none truncate'>
                {file.name}
              </div>
              <IoClose
                color='gray-dark-2 shrink'
                className='cursor-pointer w-4'
                onClick={() => onChange([file], 'remove')}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DropzoneUploader
