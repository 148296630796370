import toast from 'react-hot-toast'

export const MIME_TYPES = {
  VIDEO_MP4: 'video/mp4',
  VIDEO_WEBM: 'video/webm',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
}

export const FILE_EXTENSIONS = {
  [MIME_TYPES.VIDEO_MP4]: '.mp4',
  [MIME_TYPES.VIDEO_WEBM]: '.webm',
  [MIME_TYPES.IMAGE_JPEG]: '.jpg',
  [MIME_TYPES.IMAGE_PNG]: '.png',
}

export const getMimeType = () => {
// Detect Safari browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  // Choose MIME type based on the browser
  return isSafari ? MIME_TYPES.VIDEO_MP4 : MIME_TYPES.VIDEO_WEBM
}

export const getFileName = (prefix: string, mimeType: string): string => {
  const safeTimestamp = Date.now()
  const fileExtension = FILE_EXTENSIONS[mimeType] || 'bin'
  return `${prefix}_${safeTimestamp}${fileExtension}`
}

// Check if the camera can be accessed
export const checkCameraAccess = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({video: true})
    return true
  } catch (error) {
    const mediaError = error as Error
    if (mediaError.name === 'NotAllowedError') {
      toast.error('Access to the camera is denied!')
    } else if (mediaError.name === 'NotFoundError') {
      toast.error('No camera device found!')
    } else {
      toast.error(`Error accessing the camera: ${mediaError.message}`)
    }
    return false
  }
}

// Check if the browser supports necessary media APIs
export const checkBrowserMediaSupport = () => {
  if (!navigator.mediaDevices ||
    !navigator.mediaDevices.getUserMedia ||
    typeof MediaRecorder === 'undefined') {
    toast.error('Media Devices API is not supported in your browser')
    return false
  }
  return true
}

export const convertFilesToFormData = (files: File[], fieldName: string = 'attachment'): FormData => {
  const formData = new FormData()

  files.forEach((file, index) => {
    formData.append(`${fieldName}[${index}]`, file, file.name)
  })

  return formData
}
