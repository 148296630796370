import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

interface QuizContextType {
  quizBlockIds: string[] | null;
  setQuizBlockIds: (ids: string[] | null) => void;
  currentBlockId: string | null;
  setCurrentBlockId: (id: string) => void;
  isValid: boolean;
  setIsValid: (value: boolean) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  hasResponded: boolean;
  setHasResponded: (value: boolean) => void;
}

const QuizContext = createContext<QuizContextType | undefined>(undefined);

interface QuizProviderProps {
  children: ReactNode;
}

export const QuizProvider: FC<QuizProviderProps> = ({ children }) => {
  const [quizBlockIds, setQuizBlockIds] = useState<string[] | null>(null);
  const [currentBlockId, setCurrentBlockId] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [hasResponded, setHasResponded] = useState(false);

  const value: QuizContextType = useMemo(
    () => ({
      quizBlockIds,
      setQuizBlockIds,
      currentBlockId,
      setCurrentBlockId,
      isValid,
      setIsValid,
      errorMessage,
      setErrorMessage,
      hasResponded,
      setHasResponded,
    }),
    [quizBlockIds, currentBlockId, isValid, errorMessage, hasResponded]
  );

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};

export const useQuiz = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error("useQuiz must be used within a QuizProvider");
  }

  return context;
};
