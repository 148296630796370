import { useEffect } from "react";
import BeforeAfterFieldsDisplay from "../../components/QuizForm/CustomBlocks/BeforeAfterCarouselDisplay";
import { QuizBlockProps } from "../../types";

interface ContactsBlockProps extends QuizBlockProps<{}> {}

const BeforeAfterCarouselBlock = ({
  id,
  setIsAnswered,
  setVal,
}: ContactsBlockProps) => {
  useEffect(() => {
    setVal({ answer: true });
    setIsAnswered(true);
  }, []);

  return <BeforeAfterFieldsDisplay id={id} />;
};

export default BeforeAfterCarouselBlock;
