import {BlockConfiguration} from '../../../types'
import TaskStatusListBlock from '../../../containers/QuizCustomBlocks/TaskStatusListBlock'

export const taskStatusListConfig: BlockConfiguration = {
  name: 'task-status-list',
  supports: {
    editable: false,
  },
  attributes: {
    title: {
      type: 'string',
    },
    classnames: {
      type: 'string',
      'default': 'without-footer-button',
    },
  },
  display: TaskStatusListBlock,
}
