import Cookies from "js-cookie";
import { CookiesKey } from "../types/cookies";
import { v4 } from "uuid";

export const getSessionId = () => {
  let value = Cookies.get(CookiesKey.SESSION_ID);

  if (!value) {
    value = v4();
    Cookies.set(CookiesKey.SESSION_ID, value);
  }

  return value;
};

export const getAffiliateId = () => {
  let value = Cookies.get(CookiesKey.QUIZ_AFFILIATE_ID);

  return value;
};

export const getAffiliateSource = () => {
  let value = Cookies.get(CookiesKey.QUIZ_AFFILIATE_CMP);

  return value;
};

export const getEntrySource = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get("entrySource");

  return value;
};

export const getEntrySourceQueryParam = () => {
  const source = getEntrySource();

  return `entry_source=${source || "unknown"}`;
};

const headerPrefix = "x-quiz-aff";
const everflowClickIdHeader = `${headerPrefix}-Click-Id`;

// this headers used to analyze affiliates
// when quiz is last step of user journey
export const getCPLAffiliateHeaders = (): Record<string, string> => {
  const mergedAffDataCookie = Cookies.get(CookiesKey.AFFILIATE_UTM_MERGED);

  if (!mergedAffDataCookie) {
    return {};
  }

  const resObj: Record<string, string> = {};

  // might be problems with decoding, parsing
  try {
    const mergedDataParsed = JSON.parse(
      decodeURIComponent(mergedAffDataCookie)
    );

    Object.entries(mergedDataParsed).forEach(([key, value]) => {
      // don't want anything else in header
      if (typeof value === "string" || typeof value === "number") {
        resObj[`${headerPrefix}-${key?.replaceAll("_", "-")}`] = String(value);

        if (key === "_ef_transaction_id") {
          resObj[everflowClickIdHeader] = String(value);
        }
      }
    });

    if (
      !resObj[everflowClickIdHeader] &&
      Cookies.get(CookiesKey.QUIZ_EV_CLICK_ID)
    ) {
      resObj[everflowClickIdHeader] =
        Cookies.get(CookiesKey.QUIZ_EV_CLICK_ID) || "";
    }

    return resObj;
  } catch (e) {
    console.error(e);
    return {};
  }
};
