import {BlockConfiguration} from '../../../types'
import InputFieldBlock from '../../../containers/QuizCustomBlocks/InputFieldBlock'

export const customInputFieldConfig: BlockConfiguration = {
  name: 'custom-input-field',
  supports: {
    editable: true,
  },
  attributes: {
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    placeholder: {
      type: 'string',
    },
    maxLength: {
      type: 'number',
      'default': 100,
    },
    inputType: {
      type: 'string',
      'default': 'text',
    },
    unit: {
      type: 'string',
    },
    type: { // text, date, pressure_measurements, email
      type: 'string',
      'default': 'text',
    },
    nextBtnLabel: {
      type: 'string',
      'default': 'Next',
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
  },
  display: InputFieldBlock,
}
