import { blocksConfig } from "./configs";
import { registerBlockType } from "@quillforms/blocks";

const registerAllBlocks = () => {
  blocksConfig.forEach((block) => {
    registerBlockType(block.name, {
      supports: block.supports,
      attributes: block.attributes,
      display: block.display,
    });
  });
};

export default registerAllBlocks;
