import { FC, ReactNode, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import ProgressBar from "../../components/ProgressBar";
import { useQuiz } from "../../contexts/QuizContext";
import QuestionContentDisplay from "../../components/QuizForm/CustomBlocks/QuestionContentDisplay";
import {
  useCurrentBlock,
  useFormAnswers,
  useRendererStoreActions,
} from "@quillforms/renderer-core";
import { FaArrowLeftLong } from "react-icons/fa6";
import { WELCOME_BLOCK_NAME } from "../../constants";
import { useLocation } from "react-router-dom";

interface QuizBlockLayoutProps {
  children: ReactNode;
  currentBlockId: string;
  question?: string;
  prompt?: string;
  customHTML?: string;
  withProgressBar?: boolean;
  hidePrevButton?: boolean;
  onPrevClick?: () => void;
}

const GOALS_LABELS = {
  1: "GOALS",
  2: "BODY",
  3: "HEALTH",
  4: "LAST STEP",
};

const FORM_CHUNK_SIZE = 10;

const QuizBlockLayout: FC<QuizBlockLayoutProps> = ({
  children,
  question,
  prompt,
  customHTML,
  withProgressBar = true,
  hidePrevButton = false,
  onPrevClick,
}) => {
  const { pathname } = useLocation();
  const isQuizPage = pathname.startsWith("/quiz");

  const errorMessageRef = useRef(null);
  const { quizBlockIds, errorMessage, setCurrentBlockId } = useQuiz();
  const totalBlocks = quizBlockIds?.length;
  const currentBlock = useCurrentBlock();
  const currentBlockId = currentBlock?.id ?? "";

  const formAnswers = useFormAnswers();
  const { goPrev } = useRendererStoreActions();
  const showBackButton =
    !hidePrevButton && currentBlock && currentBlock.name !== WELCOME_BLOCK_NAME;
  const { setIsValid } = useQuiz();

  useEffect(() => {
    if (currentBlock) setCurrentBlockId(currentBlock.id);
  }, []);

  useEffect(() => {
    if (currentBlock) {
      const currentBlockRequired = currentBlock.attributes?.required;
      const isAnswered = formAnswers[currentBlock.id]?.isAnswered;
      const isValid =
        !currentBlockRequired || !!(currentBlockRequired && isAnswered);
      setIsValid(isValid);
    }
  }, [formAnswers, currentBlock?.id]);

  const questionsAmount = quizBlockIds?.length ?? 0;

  const currentPosition = (quizBlockIds?.indexOf(currentBlockId) || 0) + 1;

  const currentFormChunkIndex = Math.ceil(
    (currentPosition ?? 0) / FORM_CHUNK_SIZE
  );

  const maxChunks = Math.ceil(questionsAmount / FORM_CHUNK_SIZE);

  let chunkMax =
    (currentFormChunkIndex === maxChunks
      ? questionsAmount % FORM_CHUNK_SIZE
      : FORM_CHUNK_SIZE) || 10;

  // is displayed in progress bar
  let currentProgressPosition = Math.max(
    ((currentPosition - 1) % FORM_CHUNK_SIZE) + 1,
    1
  );

  if (!isQuizPage) {
    // disable alternative logic for
    chunkMax = (quizBlockIds?.length ?? 1) - 1;
    currentProgressPosition = quizBlockIds?.indexOf(currentBlockId) ?? 0;
  }
  return (
    <div className="relative ">
      <div className="mb-2.5 lg:mb-3.5 flex justify-center relative">
        {showBackButton && (
          <div
            onClick={() => {
              const cb = onPrevClick ?? goPrev;
              cb();
            }}
            className="text-xs sm:text-sm absolute left-0 font-medium text-gray-darker flex gap-1 items-center cursor-pointer hover:bg-silver-2 transition-all py-1 px-2.5 -ml-2.5 rounded-full"
          >
            <FaArrowLeftLong />
            <span>Back</span>
          </div>
        )}
        <span className="text-xs sm:text-sm font-greycliffCF font-bold text-gray-dark tracking-[.25em]">
          {GOALS_LABELS[currentFormChunkIndex as keyof typeof GOALS_LABELS]}
        </span>
        {totalBlocks && (
          <span className="text-xs absolute right-0 font-bold text-gray-darker">
            {currentProgressPosition}/{chunkMax}
          </span>
        )}
      </div>
      {withProgressBar && totalBlocks && (
        <div className="mt-4 sm:mt-5 lg:mt-6 mb-5 sm:mb-7 lg:mb-8">
          <ProgressBar progress={currentProgressPosition} max={chunkMax} />
        </div>
      )}
      {question && (
        <QuestionContentDisplay
          question={question}
          prompt={prompt}
          customHTML={customHTML}
        />
      )}
      {children}
      <CSSTransition
        nodeRef={errorMessageRef}
        in={!!errorMessage}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div
          ref={errorMessageRef}
          className="mt-4 text-brick-red font-medium text-sm sm:text-base"
        >
          {errorMessage}
        </div>
      </CSSTransition>
    </div>
  );
};

export default QuizBlockLayout;
