import {FC} from 'react'
import {taskStatusColor, TaskStatusConstants, taskStatusIcon} from './TaskStatus.constants'

const columns = ['Task', 'Status']

export interface Task {
  name: string;
  status?: TaskStatusConstants;
  statusLabel?: string;
  note?: string;
}

interface StatusTableProps {
  tasks: Task[];
}

const StatusTable: FC<StatusTableProps> = ({tasks}) => {
  return (
    <table className='h-72 sm:h-96 lg:h-[465px] min-w-full border border-mist'>
      <thead>
      <tr className='border border-mist grid grid-cols-2'>
        {columns.map(column => (
          <th
            key={column}
            className='py-2 sm:py-3 lg:py-4 text-lg sm:text-2xl lg:text-[26px] lg:leading-[29px] text-center font-azoSansWeb font-medium text-deep-purple'
          >
            {column}
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {tasks.map((task, i) => (
        <tr key={i} className='grid grid-cols-2 divide-x'>
          <td
            className='px-4 sm:px-6 lg:px-8 py-2.5 sm:py-4 lg:py-6 text-sm sm:text-lg font-azoSansWeb font-medium text-black'
          >
            {task.name}
          </td>
          <td
            className={`${task.status ? taskStatusColor[task.status] : ''} gap-1.5 sm:gap-2 px-2.5 sm:px-3 lg:px-4 py-3.5 sm:py-5 lg:py-6 flex border-mist`}
          >
            {task.status && (
              <div className={`mt-0.5 ${task.statusLabel ? '' : 'w-full'}`}>
                {taskStatusIcon[task.status]}
              </div>
            )}
            {task.statusLabel && (
              <div
                className='text-sm sm:text-lg lg:text-[19px] lg:leading-[25px] font-azoSansWeb font-medium break-words'
              >
                {task.statusLabel}
                {task.note && (
                  <div className='font-normal italic break-words'>{task.note}</div>
                )}
              </div>
            )}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default StatusTable
