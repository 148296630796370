import { useState } from "react";
import DisclaimersDisplay from "../../components/QuizForm/CustomBlocks/DisclaimerDisplay/DisclaimersDisplay";
import { QuizBlockProps } from "../../types";

interface ContactsBlockProps extends QuizBlockProps<{}> {}

const DisclaimersBlock = ({
  id,
  val,
  setVal,
  setIsAnswered,
}: ContactsBlockProps) => {
  const [values, setValues] = useState(
    val || {
      sideEffects: false,
      pregnancy: false,
      telehealthConsent: false,
    }
  );

  const onChange = (name: string) => {
    const newValues = { ...values, [name]: !values[name] };
    setValues(newValues);

    if (Object.values(newValues).every((value) => value === true)) {
      setIsAnswered(true);
      setVal({ answer: true });
    } else {
      setIsAnswered(false);
    }
  };

  return (
    <DisclaimersDisplay isChecked={{ ...values }} onChange={onChange} id={id} />
  );
};

export default DisclaimersBlock;
