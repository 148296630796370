import { BlockConfiguration } from "../../../types";
import ContactsCpaSkipBlock from "../../../containers/QuizCustomBlocks/ContactsCpaCopyBlock";

export const contactFieldsCpaSkipConfig: BlockConfiguration = {
  name: "contacts-fields-cpa-skip",
  supports: {
    editable: true,
  },
  attributes: {
    classnames: {
      type: "string",
      default: "without-footer-button",
    },
    required: {
      type: "boolean",
    },
    isAnswered: {
      type: "boolean",
    },
  },
  display: ContactsCpaSkipBlock,
};
