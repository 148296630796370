import {FC} from 'react'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'

interface InputFieldDisplayProps {
  id: string;
  value: string | undefined;
  onChange: (value: string) => void;
  inputType: string;
  question: string;
  prompt?: string;
  placeholder?: string;
  maxLength?: number;
  unit?: string;
}

const InputFieldDisplay: FC<InputFieldDisplayProps> = ({
  id,
  value = '',
  question,
  prompt,
  inputType,
  placeholder,
  maxLength,
  unit,
  onChange,
}) => {
  return (
    <QuizBlockLayout
      currentBlockId={id}
      question={question}
      prompt={prompt}
    >
      <div className='relative'>
        <input
          value={value}
          type={inputType}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={({target}) => onChange(target.value)}
          className={`
          ${value ? 'border-medium-green' : 'border-purple'}
          text-lg sm:text-xl lg:text-3xl pb-2 sm:pb-3 lg:pb-4 border-b-4 w-full outline-none text-center placeholder:text-cool-gray transition duration-500
          `}
        />
        {unit && <span className='text-sm sm:text-base lg:text-lg mb-2 absolute bottom-0 right-0'>{unit}</span>}
      </div>
    </QuizBlockLayout>
  )
}

export default InputFieldDisplay
