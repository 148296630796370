import axios from "axios";
import {
  getSessionId,
  getAffiliateId,
  getAffiliateSource,
  getCPLAffiliateHeaders,
  getEntrySource,
} from "../utils/getSessionId";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Session-Id": getSessionId(),
    "X-affiliate": getAffiliateId(),
    "X-aff-source": getAffiliateSource(),
    "X-entry-source": getEntrySource(),
    ...getCPLAffiliateHeaders(),
  },
});
