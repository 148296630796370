import {BlockConfiguration} from '../../../types'
import GoodbyeScreenDisplay from '../../../components/QuizForm/CustomBlocks/GoodbyeScreenDisplay'

export const customGoodbyeScreenConfig: BlockConfiguration = {
  name: 'custom-goodbye-screen',
  supports: {
    editable: false,
  },
  attributes: {
    classnames: {
      type: 'string',
      'default': 'without-footer-button',
    },
    title: {
      type: 'string',
    },
    subtitle: {
      type: 'string',
    },
  },
  display: GoodbyeScreenDisplay,
}
