import {FC} from 'react'
import {IoCheckmarkCircle} from 'react-icons/io5'
import {FaAward} from 'react-icons/fa'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'
import {delivery, productImg} from '../../../assets/images'

const benefits = ['U.S. DOCTORS', 'LICENSED PHARMACY', 'LOWEST PRICE GUARANTEED']

interface DiscountDisplayProps {
  id: string;
  title: string;
}

const DiscountDisplay: FC<DiscountDisplayProps> = ({id, title}) => {
  return (
    <QuizBlockLayout currentBlockId={id} withProgressBar={false}>
      <h1 className='quiz-title'>{title}</h1>

      <div className='font-azoSansWeb font-bold'>
        <h2
          className='px-2.5 sm:px-3.5 py-1.5 sm:py-2 text-xl sm:text-2xl lg:text-3xl bg-deep-purple text-white text-center italic'
        >
          Your Plan
        </h2>
        <div className='gap-1.5 px-2.5 sm:px-3.5 bg-primary-green flex items-center justify-center'>
          <div>
            <img src={delivery} alt='delivery'/>
          </div>
          <h3 className='py-1 sm:py-1.5 text-base sm:text-lg italic text-white'>
            Act Fast: Secure Your Free 3-Day Delivery!
          </h3>
        </div>

        <div className='px-3 sm:px-4 pt-2 sm:pt-3 pb-5 sm:pb-7 gap-3 sm:gap-4 bg-frost flex'>
          <div className='flex-1 w-1/2'>
            <div className='mb-4 sm:mb-5 lg:mb-6'>
              <img src={productImg} alt='product'/>
            </div>
            <div className='mb-2 sm:mb-2.5 lg:mb-3 flex items-center text-deep-purple'>
              <span className='text-lg sm:text-xl lg:text-[21px] lg:leading-[24px]'>
                Mr.RX
              </span>
              <span className='h-2.5'>&#174;</span>
              <span className='text-xs sm:text-[13px] sm:leading-[21px] font-normal'>
                Generic Viagra
              </span>
            </div>
            <p className='text-sm sm:text-[15px] sm:leading-[26px] text-black italic font-medium'>
              50mg Generic Wegovy &reg;
            </p>
            <p
              className='text-sm sm:text-[15px] sm:leading-[26px] mb-2 sm:mb-2.5 lg:mb-3 text-black italic font-medium'
            >
              1 month injection regimen
            </p>
            <div className='flex gap-1 items-center text-primary-green'>
              <div><FaAward/></div>
              <p className='text-sm sm:text-[15px] sm:leading-[18px]'>
                FDA Approved Treatment
              </p>
            </div>
          </div>

          <div className='flex-1 w-1/2'>
            <p className='text-red text-sm sm:text-[15px] sm:leading-[24px] italic'>
              Avg Retail Price: $28.00 each
            </p>
            <div className='gap-1.5 sm:gap-2 lg:gap-2.5 mb-2 sm:mb-2.5 lg:mb-3 flex'>
              <div className='text-xl sm:text-[26px] sm:leading-[34px] strike-through text-deep-purple'>
                $1.99
              </div>
              <div className='text-base sm:text-[23px] text-primary-green'>
                56% OFF
              </div>
            </div>
            <div className='custom-chip'>$0.87each</div>
            <ul className='gap-1 sm:gap-2 lg:gap-[9px] mb-2 sm:mb-3 flex flex-col'>
              {benefits.map((benefit) => (
                <li key={benefit} className='flex items-center'>
                  <div><IoCheckmarkCircle className='w-5 h-5 text-primary-green pr-1'/></div>
                  <span className='text-xs sm:text-sm tracking-[-0.015em]'>
                    {benefit}
                   </span>
                </li>
              ))}
            </ul>
            <p className='text-[9px] text-gray tracking-[-0.024em] leading-tight font-normal'>
              *Price quoted is unit price of 9 tablet package with $10 promotional discount. Only applicable to first
              order.
            </p>
          </div>
        </div>
      </div>
    </QuizBlockLayout>
  )
}

export default DiscountDisplay
