import {BlockConfiguration} from '../../../types'
import MultipleChoiceBlock from '../../../containers/QuizCustomBlocks/MultipleChoiceBlock'

export const customMultipleChoiceConfig: BlockConfiguration = {
  name: 'custom-multiple-choice',
  supports: {
    editable: true,
  },
  attributes: {
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    classnames: {
      type: 'string',
      'default': 'without-footer-button',
    },
    nextBtnLabel: {
      type: 'string',
      'default': 'Next',
    },
    options: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          value: {
            type: 'string',
          },
          label: {
            type: 'string',
          },
          innerBlock: {
            type: 'object',
            properties: {
              label: {
                type: 'string',
              },
              type: {
                type: 'string', // textarea
                'default': 'textarea',
              },
              placeholder: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
  },
  display: MultipleChoiceBlock,
}
