import toast from "react-hot-toast";
import { postAnswer, uploadDocument } from "../api/quiz";
import axios from "axios";

export type ErrorResponse = {
  status?: number;
  responseMessage?: any;
};

export const submitAnswer = async (
  blockName: string,
  data: any,
  questionOrder?: number
) => {
  try {
    if (blockName === "custom-attachment") {
      for (const file of data.value.files) {
        await uploadDocument({
          userId: data.userId,
          type: data.value.type,
          file,
        });
      }
    } else {
      await postAnswer(data, questionOrder);
    }
  } catch (error) {
    let errorMessage = "An error occurred";
    if (axios.isAxiosError(error)) {
      return {
        status: error?.response?.status,
        responseMessage: error?.response?.data,
      } satisfies ErrorResponse;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    toast.error(errorMessage);
  }
};
