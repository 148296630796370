import {BlockConfiguration} from '../../../types'
import AttachmentBlock from '../../../containers/QuizCustomBlocks/AttachmentBlock'

export const customAttachmentConfig: BlockConfiguration = {
  name: 'custom-attachment',
  supports: {
    editable: true,
    required: true,
  },
  attributes: {
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    customHTML: {
      type: 'string',
    },
    video: {
      type: 'object',
      properties: {
        label: {
          type: 'string',
        },
        src: {
          type: 'string',
        },
      },
    },
    type: {
      type: 'string', // image, video
      'default': 'video',
    },
    answerType: {
      type: 'string', // id, photo, video
      'default': 'photo',
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
    maxFiles: {
      type: 'number',
    },
    maxFileSizeMB: {
      type: 'number',
    },
  },
  display: AttachmentBlock,
}
