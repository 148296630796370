import { FC } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import ProgressBar from "@ramonak/react-progress-bar";

interface InputFieldDisplayProps {
  id: string;
  slide?: string;
  progressNumber: number;
}

const ResultCalculationDisplay: FC<InputFieldDisplayProps> = ({
  id,
  slide,
  progressNumber,
}) => {
  return (
    <QuizBlockLayout hidePrevButton currentBlockId={id} withProgressBar={false}>
      <p className="section-title" style={{ fontSize: 18 }}>
        SkinnyRx identifies and addresses the root causes of your metabolic
        issues...
      </p>
      <img
        className="transition-all mb-8	"
        src={slide}
        alt="Results calculation..."
      />
      <ProgressBar
        className="animate-pulse duration-300"
        completed={progressNumber}
        isLabelVisible={false}
      />
      <p className="section-title my-4 " style={{ fontSize: 18 }}>
        Calculating your results…
      </p>
    </QuizBlockLayout>
  );
};

export default ResultCalculationDisplay;
