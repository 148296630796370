import clsx from "clsx";
import {
  ChangeEvent,
  InputHTMLAttributes,
  SyntheticEvent,
  useMemo,
} from "react";
import { withMask } from "use-mask-input";

interface IProps {
  label: string;
  value: string;
  name: string;
  unit?: string;
  required?: boolean;
  error?: string | boolean;
  type?: "text" | "phone" | "verifyCode" | "dob";
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
}

const InputField = ({
  label,
  value,
  unit,
  name,
  required,
  error,
  type = "text",
  onChange,
  onBlur,
}: IProps) => {
  const typeFieldProps: InputHTMLAttributes<HTMLInputElement> = useMemo(() => {
    if (type === "phone") {
      return {
        ref: withMask("(999) 999-9999", {
          jitMasking: true,
          showMaskOnHover: false,
        }),
        inputMode: "tel",
        pattern: "[0-9]*",
      };
    }
    if (type === "verifyCode") {
      return {
        ref: withMask("9999", {
          jitMasking: true,
          showMaskOnHover: false,
        }),
        inputMode: "numeric",
        pattern: "[0-9]*",
      };
    }
    if (type === "dob") {
      return {
        ref: withMask("99/99/9999", {
          jitMasking: true,
          showMaskOnHover: false,
        }),
        inputMode: "numeric",
        pattern: "[0-9]*",
      };
    }
    return {};
  }, [type]);

  return (
    <div className={clsx("w-full flex flex-col")}>
      <label className={clsx("text-sm mb-1 text-left", error && "text-red")}>
        {label}
      </label>
      <div className="relative flex items-center">
        <input
          {...typeFieldProps}
          value={value || ""}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          type="text"
          className={clsx(
            "p-2 sm:p-3 !text-base !sm:text-lg border-[3px] w-full border-soft rounded-lg focus:border-medium-green focus:outline-none",
            error && "border-red border-[1px]"
          )}
          required={required}
        />
        {unit && (
          <div className="absolute right-1 pr-5 pl-2 py-2 bg-white">{unit}</div>
        )}
      </div>
      {/* if error is boolean don't show error message */}
      {error && typeof error === "string" && (
        <span className="text-left mt-2	text-red text-sm">{error}</span>
      )}
    </div>
  );
};

export { InputField };
