import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuiz } from "../contexts/QuizContext";
import { CustomFormBlock } from "../types";
import { decorateBlocks } from "../utils";

export const useFetchBlocks = (
  fetchBlocks: () => Promise<CustomFormBlock[]>
) => {
  const [loading, setLoading] = useState(true);
  const [quizBlocks, setQuizBlocks] = useState<CustomFormBlock[] | null>(null);

  const { setQuizBlockIds } = useQuiz();

  useEffect(() => {
    (async () => {
      try {
        const fetchedBlocks: CustomFormBlock[] = await fetchBlocks();
        const processedBlocks = decorateBlocks(fetchedBlocks);
        setQuizBlocks(processedBlocks);
        setQuizBlockIds(fetchedBlocks.map((block) => `${block.id}`));
      } catch (error) {
        toast.error(
          error instanceof Error ? error.message : "An error occurred"
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, quizBlocks };
};
