import {BlockConfiguration} from '../../../types'
import WelcomeScreenDisplay from '../../../components/QuizForm/CustomBlocks/WelcomeScreenDisplay'
import {WELCOME_BLOCK_NAME} from '../../../constants'

export const customWelcomeScreenConfig: BlockConfiguration = {
  name: WELCOME_BLOCK_NAME,
  supports: {
    editable: false,
  },
  attributes: {
    nextBtnLabel: {
      type: 'string',
    },
    classnames: {
      type: 'string',
      'default': 'custom-welcome-screen',
    },
    title: {
      type: 'string',
    },
    subtitle: {
      type: 'string',
    },
  },
  display: WelcomeScreenDisplay,
}
