export const resultsPageBase = process.env.REACT_APP_FRONT_BASE_URL;
export const IS_SCRIPT_SITE = resultsPageBase?.includes("scriptderm");

export const groupedFooterLinks = IS_SCRIPT_SITE
  ? [
      [
        {
          name: "Terms of Use",
          path: "https://scriptderm.com/terms-conditions",
        },
        {
          name: "Privacy Policy",
          path: "https://scriptderm.com/privacy-policy",
        },
        { name: "Contact Us", path: "https://scriptderm.com/contact" },
      ],
    ]
  : [
      [
        { name: "Terms of Use", path: "https://skinnyrx.com/terms" },
        { name: "Privacy Policy", path: "https://skinnyrx.com/privacy" },
        { name: "Contact Us", path: "https://skinnyrx.com/contact" },
      ],
      // [{ name: "Physician Code of Conduct", path: "#" }],
    ];

export const infoBannerLinks = [
  { name: "Protected Under Federal Law.", path: "#" },
];
