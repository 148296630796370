import classNames from "classnames";
import clsx from "clsx";
import { ChangeEvent, SyntheticEvent } from "react";

type Option = {
  value: string;
  label: string;
};

interface IProps {
  label: string;
  options: Option[];
  value: string;
  name: string;
  required?: boolean;
  error?: string | boolean;
  type?: "text" | "phone" | "verifyCode" | "dob";
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: SyntheticEvent<HTMLSelectElement>) => void;
}

const SelectField = ({
  label,
  value,
  options,
  name,
  required,
  error,
  onChange,
  onBlur,
}: IProps) => {
  return (
    <div className={clsx("w-full flex flex-col")}>
      <label className={clsx("text-sm mb-1 text-left", error && "text-red")}>
        {label}
      </label>
      <div className="relative flex items-center">
        <select
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          required={required}
          className={classNames(
            "appearance-none form-select focus-visible:outline-[1px] p-2 sm:p-3 !text-base !sm:text-lg border-[3px] w-full border-soft rounded-lg focus:border-medium-green focus:outline-none",
            error && "border-red border-[1px]"
          )}
        >
          <option value="">{""}</option>

          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {/* if error is boolean don't show error message */}
      {error && typeof error === "string" && (
        <span className="text-left mt-2	text-red text-sm">{error}</span>
      )}
    </div>
  );
};

export { SelectField };
