import isEqual from "lodash/isEqual";
import {
  useFieldAnswer,
  useFormAnswers,
  useRendererStoreActions,
} from "@quillforms/renderer-core";
import { useQuiz } from "../contexts/QuizContext";
import { useEffect, useState } from "react";
import { AnswerValue, CustomFormBlock } from "../types";
import { decorateBlocks } from "../utils";
import { RendererAnswersState } from "@quillforms/renderer-core/build-types/store/types";

export const mapBlockTree = (
  blocks: CustomFormBlock[],
  answers: RendererAnswersState
): CustomFormBlock[] => {
  return blocks
    .map((block) => {
      const selectedAnswer =
        (answers[block.id]?.value as { answer: string })?.answer ??
        (block.answers?.[0]?.value as { answer: string })?.answer ??
        "";

      if (
        selectedAnswer &&
        Object.values(block.attributes.conditionalBlocks ?? {}).length
      ) {
        const availableBlocks =
          block.attributes.conditionalBlocks[selectedAnswer as string];

        return [block, ...mapBlockTree(availableBlocks || [], answers)];
      }
      return [block];
    })
    .flat();
};

export const useConditionalBlocks = (
  initialBlocks: CustomFormBlock[] | null
) => {
  const [blocksWithConditionals, setBlocksWithConditionals] = useState<
    CustomFormBlock[] | null
  >(initialBlocks);

  const { setQuizBlockIds, currentBlockId } = useQuiz();
  const formAnswers = useFormAnswers();
  const fieldAnswer = useFieldAnswer(currentBlockId) as AnswerValue;

  const mapBlocks = (block: CustomFormBlock) => {
    if (!block) return [];

    const blocksArray = [block];

    if (block.attributes?.conditionalBlocks) {
      // @ts-ignore
      const answer = formAnswers[block.id]?.value?.answer;

      const blocksToAdd: CustomFormBlock[] | undefined =
        block.attributes.conditionalBlocks[answer];
      const blocksToAddMapped = blocksToAdd?.map(mapBlocks)?.flat() ?? [];

      if (blocksToAddMapped?.length) {
        const processedBlocks = decorateBlocks(blocksToAddMapped);

        blocksArray.push(...processedBlocks);
      }
    }
    return blocksArray?.flat() ?? [];
  };

  const processConditionalBlocks = () => {
    if (!initialBlocks) return;

    const processedBlocks = initialBlocks.map(mapBlocks).flat();

    if (!isEqual(blocksWithConditionals, processedBlocks)) {
      setQuizBlockIds(processedBlocks.map((block) => block.id));
      setBlocksWithConditionals(processedBlocks);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (initialBlocks) processConditionalBlocks();
    // @ts-ignore
  }, [fieldAnswer?.answer, initialBlocks, currentBlockId]);

  return { blocksWithConditionals };
};
