import {FC, MouseEvent} from 'react'

interface TextareaProps {
  id: string;
  value: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  label?: string;
  inputStateIndicator?: boolean;
  onClick?: (e: MouseEvent<HTMLTextAreaElement>) => void;
}

const Textarea: FC<TextareaProps> = ({
  id,
  value,
  onChange,
  placeholder,
  label,
  inputStateIndicator = false,
  onClick,
}) => {
  return (
    <div
      className={`
      ${inputStateIndicator
        ? `pb-4 border-b-4 ${value ? 'border-medium-green' : 'border-purple'}`
        : ''} 
        flex flex-col
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className='mb-1.5 sm:mb-2.5 text-base sm:text-lg lg:text-[19px] lg:leading-[29px] font-greycliffCF text-black font-normal'
        >
          {label}
        </label>
      )}
      <textarea
        id={id}
        rows={4}
        value={value}
        placeholder={placeholder}
        onChange={({target}) => onChange(target.value)}
        onClick={onClick}
        className='textarea text-19-important'
      />
    </div>
  )
}

export default Textarea
