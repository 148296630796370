import React, { FC, MouseEvent, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import Textarea from "../../Textarea";

interface MultipleChoiceDisplayProps {
  values: string[];
  onChange: (values: string) => void;
  id: string;
  question?: string;
  prompt?: string;
  options: {
    value: string;
    label: string;
    innerBlock?: { label: string; type: string; placeholder?: string };
  }[];
  textareaValue: Record<string, string | undefined> | undefined;
  onChangeTextarea: (value: string) => (textareaValue: string) => void;
}

const MultipleChoiceDisplay: FC<MultipleChoiceDisplayProps> = ({
  id,
  values,
  textareaValue,
  question,
  prompt,
  options,
  onChange,
  onChangeTextarea,
}) => {
  const nodeRef = useRef(null);

  const handleClick = (event: MouseEvent, value: string) => {
    event.preventDefault();
    onChange(value);
  };

  return (
    <QuizBlockLayout currentBlockId={id} question={question} prompt={prompt}>
      <div className="my-4 sm:my-6 lg:my-8 flex flex-col space-y-3 sm:space-y-4">
        {options.map(({ value, label, innerBlock }) => (
          <label
            key={value}
            htmlFor={value}
            className="group p-3 sm:p-5 lg:p-6 flex border border-slate-gray rounded-md cursor-pointer hover:border-dark-purple"
            onClick={(e) => handleClick(e, value)}
          >
            <div className="custom-checkbox pt-1">
              <input
                type="checkbox"
                id={value}
                value={value}
                checked={values.includes(value)}
                onChange={({ target }) => onChange(target.value)}
                className="w-3.5 sm:w-4 lg:w-5 h-3.5 sm:h-4 lg:h-5 cursor-pointer hidden-checkbox"
              />
              <div className="styled-checkbox" />
            </div>
            <div className="grow">
              <span className="text-base sm:text-lg lg:text-xl pl-6 sm:pl-7 lg:pl-8 text-charcoal font-bold">
                {label}
              </span>
              {/* {value === "none" && (
                <span className="block text-sm pl-6 sm:pl-7 lg:pl-8">
                  (Best answer)
                </span>
              )} */}
              <CSSTransition
                nodeRef={nodeRef}
                in={innerBlock && values.includes(value)}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <label
                  ref={nodeRef}
                  htmlFor={innerBlock?.label}
                  className="gap-1.5 sm:gap-2.5 mt-4 sm:mt-5 flex flex-col"
                >
                  <span className="text-sm sm:text-base lg:text-[17px] font-greycliffCF font-normal">
                    {innerBlock?.label}
                  </span>
                  {innerBlock?.type === "textarea" && (
                    <Textarea
                      id={innerBlock.label}
                      placeholder={innerBlock.placeholder}
                      value={textareaValue?.[value]}
                      onChange={onChangeTextarea(value)}
                      onClick={(e: MouseEvent<HTMLTextAreaElement>) =>
                        e.stopPropagation()
                      }
                    />
                  )}
                </label>
              </CSSTransition>
            </div>
          </label>
        ))}
      </div>
    </QuizBlockLayout>
  );
};

export default MultipleChoiceDisplay;
