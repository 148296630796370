import {FC} from 'react'
import QuizBlockLayout from '../../../layouts/QuizBlockLayout'
import {IoPlayCircleOutline} from 'react-icons/io5'
import DropzoneUploader from '../../../containers/DropzoneUploader'
import {BaseAttachmentBlockAttributes} from '../../../containers/QuizCustomBlocks/AttachmentBlock'
import CustomWebcam from '../../../containers/CustomWebcam'

interface AttachmentDisplayProps extends BaseAttachmentBlockAttributes {
  id: string;
  value: File[] | undefined;
  onChange: (value: File[], action: 'add' | 'remove') => void;
  maxFiles: number;
  maxFileSizeMB: number;
}

const AttachmentDisplay: FC<AttachmentDisplayProps> = ({
  id,
  question,
  prompt,
  customHTML,
  video,
  type,
  maxFiles,
  maxFileSizeMB,
  value,
  onChange,
}) => {
  return (
    <QuizBlockLayout
      currentBlockId={id}
      question={question}
      prompt={prompt}
      customHTML={customHTML}
    >
      {video && (
        <a
          href={video.src}
          target='_blank'
          className='gap-1 -mt-2 sm:-mt-3 lg:-mt-4 mb-4 sm:mb-5 lg:mb-6 flex items-center'
        >
          <IoPlayCircleOutline color='blue'/>
          <span className='text-sm sm:text-base text-blue'>
           {video.label}
         </span>
        </a>
      )}
      <div className='flex gap-2.5 sm:gap-3 lg:gap-4'>
        <div className='flex-1'>
          <DropzoneUploader
            value={value}
            type={type}
            maxFiles={maxFiles}
            maxFileSizeMB={maxFileSizeMB}
            onChange={onChange}
          />
        </div>
        <div className='flex-1 hidden lg:block'>
          <CustomWebcam
            type={type}
            onChange={onChange}
          />
        </div>
      </div>
    </QuizBlockLayout>
  )
}

export default AttachmentDisplay
