import {FC, useCallback} from 'react'
import AttachmentDisplay from '../../components/QuizForm/CustomBlocks/AttachmentDisplay'
import {answerType, BaseAttributes, QuizBlockProps} from '../../types'
import {DEFAULT_MAX_FILE_SIZE_MB, DEFAULT_MAX_FILES} from '../../constants'

export interface BaseAttachmentBlockAttributes extends BaseAttributes {
  type: 'video' | 'image';
  customHTML?: string;
  video?: { src: string, label: string };
  maxFiles?: number;
  maxFileSizeMB?: number;
}

interface AttachmentBlockAttributes extends BaseAttachmentBlockAttributes {
  answerType: answerType;
}

interface AttachmentBlockProps extends QuizBlockProps<AttachmentBlockAttributes> {
  val: { type: answerType, files: File[] } | undefined;
}

const AttachmentBlock: FC<AttachmentBlockProps> = ({
  val,
  setVal,
  id,
  attributes,
  setIsAnswered,
}) => {
  const {
    question,
    prompt,
    customHTML,
    video,
    type,
    answerType,
    maxFiles = DEFAULT_MAX_FILES,
    maxFileSizeMB = DEFAULT_MAX_FILE_SIZE_MB,
  } = attributes

  const handleChange = useCallback((value: File[], action: 'add' | 'remove') => {
    if (!val?.files) {
      setVal({type: answerType, files: value})
      setIsAnswered(true)
      return
    }
    let updatedVal: File[] = []
    if (action === 'add') {
      const existingFileNames = val.files.map(file => file.name) || []
      const uniqueFiles = value.map(file => {
        let newName = file.name
        let copyNumber = 1

        while (existingFileNames.includes(newName)) {
          const nameParts = file.name.split('.')
          const extension = nameParts.pop()
          const baseName = nameParts.join('.')
          newName = `${baseName}(${copyNumber++}).${extension}`
        }

        existingFileNames.push(newName)
        return new File([file], newName, {type: file.type})
      })

      updatedVal = [...val.files, ...uniqueFiles]
    } else if (action === 'remove' && value[0]) {
      updatedVal = val.files.filter(file => file.name !== value[0].name)
    }
    const newVal = updatedVal.length ? {type: answerType, files: updatedVal} : undefined
    setVal(newVal)
    setIsAnswered(!!newVal)
  }, [val?.files, answerType])

  return (
    <AttachmentDisplay
      id={id}
      question={question}
      prompt={prompt}
      customHTML={customHTML}
      video={video}
      type={type}
      maxFiles={maxFiles}
      maxFileSizeMB={maxFileSizeMB}
      value={val?.files}
      onChange={handleChange}
    />
  )
}

export default AttachmentBlock
