import { useParams } from "react-router-dom";
import QuizView, { QuizViewProps } from "../containers/QuizView";
import { QuizProvider } from "../contexts/QuizContext";
import { useMemo } from "react";
import { API_PATHS } from "../constants";
import {
  fetchBlocks as fetchBlocksApi,
  uploadDocument as uploadDocumentApi,
} from "../api/quiz";
//
import { submitAnswer as submitAnswerApi } from "../services/quizServices";
import { UploadDocumentParamsCarr } from "../types";
import { CookiesKey } from "../types/cookies";
import Cookies from "js-cookie";

interface IProps {
  type: "quiz" | "form";
}

const QuizPage = ({ type }: IProps) => {
  const { quizId, userId } = useParams();

  let content = null;

  // prepare special loading methods per page type
  const commonProps = useMemo(() => {
    let props = {} as QuizViewProps;

    if (quizId) {
      props = {
        fetchBlocks: () => fetchBlocksApi(API_PATHS.QUIZ_BLOCKS(quizId)),
        submitAnswer: (blockName: string, data: any, order?: number) =>
          submitAnswerApi(
            blockName,
            {
              ...data,
              quizId,
              userId: Cookies.get(CookiesKey.SESSION_ID),
            },
            order
          ),
        // quiz can't handle files for now
        uploadDocument: () => {},
        isV2: true,
      };
    }

    if (userId) {
      props = {
        fetchBlocks: () => fetchBlocksApi(API_PATHS.FORM_BLOCKS(userId)),
        submitAnswer: (blockName: string, data: any, order?: number) =>
          submitAnswerApi(blockName, { ...data, userId }, order),

        uploadDocument: (params: UploadDocumentParamsCarr) =>
          uploadDocumentApi({ ...params, userId }),
        isV2: false,
      };
    }

    return props;
  }, [userId, quizId]);

  // make sure url params initialized and not conflicting
  const isInitializedForm = type === "form" && !!userId;
  const isInitializedQuiz = type === "quiz" && !!quizId;

  if (isInitializedForm || isInitializedQuiz) {
    content = <QuizView {...commonProps} key={quizId ? quizId : userId} />;
  }

  return <QuizProvider>{content}</QuizProvider>;
};

export default QuizPage;
