import { BlockConfiguration } from "../../../types";
import ResultCalculationBlock from "../../../containers/QuizCustomBlocks/ResultCalculationBlock";

export const progressCalculationConfig: BlockConfiguration = {
  name: "progress-calculation",

  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
    },
    isAnswered: {
      type: "boolean",
    },
  },
  display: ResultCalculationBlock,
};
