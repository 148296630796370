import { FC, useCallback, useEffect } from "react";
import SelectSexDisplay from "../../components/QuizForm/CustomBlocks/SelectSexDisplay";
import { QuizBlockProps } from "../../types";
import { useQuiz } from "../../contexts/QuizContext";

interface SelectSexBlockAttribute {
  options: { value: string; label: string }[];
  question: string;
  prompt?: string;
}

interface SelectSexBlockProps extends QuizBlockProps<SelectSexBlockAttribute> {}

const SelectSexBlock: FC<SelectSexBlockProps> = ({
  id,
  attributes,
  setIsAnswered,
  val,
  setVal,
  next,
}) => {
  const { question, prompt, options } = attributes;
  const { hasResponded, setHasResponded } = useQuiz();

  useEffect(() => {
    if (val?.answer && hasResponded) {
      setTimeout(() => {
        next();
      }, 700);
      setHasResponded(false);
    }
  }, [val?.answer, hasResponded]);

  const handleClick = useCallback((value: string) => {
    setVal({ answer: value });
    setIsAnswered(true);
    setHasResponded(true);
  }, []);

  return (
    <SelectSexDisplay
      id={id}
      question={question}
      prompt={prompt}
      options={options}
      value={val?.answer}
      onClick={handleClick}
    />
  );
};

export default SelectSexBlock;
