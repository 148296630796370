import { FC, useCallback, useEffect, useState } from "react";
import MultipleChoiceDisplay from "../../components/QuizForm/CustomBlocks/MultipleChoiceDisplay";
import { QuizBlockProps } from "../../types";
import { useQuiz } from "../../contexts/QuizContext";

interface MultipleChoiceBlockAttribute {
  question?: string;
  prompt?: string;
  options: {
    value: string;
    label: string;
    innerBlock?: { label: string; type: string; placeholder?: string };
  }[];
}

type Val =
  | { answer: Array<string>; inputValue?: Record<string, string | undefined> }
  | undefined;

interface MultipleChoiceBlockProps
  extends QuizBlockProps<MultipleChoiceBlockAttribute> {
  val: Val;
}

const MultipleChoiceBlock: FC<MultipleChoiceBlockProps> = ({
  val,
  setVal,
  next,
  id,
  attributes,
  setIsAnswered,
}) => {
  const { question, prompt, options } = attributes;
  const [currentValue, setCurrentValue] = useState(val?.answer || []);
  const [textareaValue, setTextareaValue] = useState(val?.inputValue);
  const { hasResponded, currentBlockId, setHasResponded } = useQuiz();

  useEffect(() => {
    if (hasResponded && val?.answer.length === 1 && val?.answer[0] === "none") {
      setHasResponded(false);
      setTimeout(() => next(), 800);
    }
  }, [hasResponded, val]);

  useEffect(() => {
    if (!val?.answer?.length) {
      setIsAnswered(false);
      return;
    }

    const hasValidAnswers = options.every((option) =>
      val?.answer.includes(option.value) && option.innerBlock
        ? !!val?.inputValue?.[option.value]
        : true
    );

    if (currentBlockId === id) {
      setHasResponded(true);
      setIsAnswered(hasValidAnswers);
    }
  }, [val]);

  const handleCheckboxChange = useCallback(
    (value: string) => {
      const updatedVal = currentValue.includes(value)
        ? currentValue.filter((item: string) => item !== value)
        : [...currentValue, value];

      const updatedTextareaValue = updatedVal.includes(value)
        ? textareaValue
        : { ...textareaValue, [value]: undefined };

      setCurrentValue(updatedVal);
      setTextareaValue(updatedTextareaValue);
      setVal(
        updatedVal.length
          ? { answer: updatedVal, inputValue: updatedTextareaValue }
          : null
      );
    },
    [currentValue, textareaValue]
  );

  const handleTextareaInputChange = useCallback(
    (value: string) => (textareaValue: string) => {
      setVal({
        answer: val?.answer,
        inputValue: { ...(val?.inputValue ?? {}), [value]: textareaValue },
      });
      setTextareaValue((prev) => ({ ...(prev || {}), [value]: textareaValue }));
    },
    [val]
  );

  return (
    <MultipleChoiceDisplay
      id={id}
      values={currentValue}
      textareaValue={textareaValue}
      question={question}
      prompt={prompt}
      options={options}
      onChange={handleCheckboxChange}
      onChangeTextarea={handleTextareaInputChange}
    />
  );
};

export default MultipleChoiceBlock;
