import { FC } from "react";

interface IProps {
  isChecked: boolean;
  onChange: (name: string) => void;
  name: string;
  label: string;
}

const CustomCheckbox: FC<IProps> = ({
  isChecked,
  onChange,
  name,
  label = "By checking this box, you acknowledge the information below",
}) => {
  return (
    <label
      htmlFor={name}
      className="group mt-5 p-3 sm:p-5 lg:p-6 flex border border-slate-gray rounded-md cursor-pointer hover:border-dark-purple"
    >
      <div className="custom-checkbox pt-1">
        <input
          type="checkbox"
          id={name}
          checked={isChecked}
          onChange={() => onChange(name)}
          className="w-3.5 sm:w-4 lg:w-5 h-3.5 sm:h-4 lg:h-5 cursor-pointer hidden-checkbox"
        />
        <div className="styled-checkbox" />
      </div>
      <div className="grow">
        <p className="text-base sm:text-lg lg:text-xl ml-6 sm:ml-7 lg:ml-8 text-charcoal font-bold">
          {label}
        </p>
      </div>
    </label>
  );
};

export default CustomCheckbox;
