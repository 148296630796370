import { ChangeEvent, FC, SyntheticEvent } from "react";
import QuizBlockLayout from "../../../layouts/QuizBlockLayout";
import { InputField } from "../../common/input";

interface InputFieldDisplayProps {
  id: string;
  values: Record<string, string>;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: SyntheticEvent<HTMLInputElement>) => void;
  error: Record<string, string | boolean>;
  isCpaFunnel?: boolean;
  isCpaSkipFunnel?: boolean;
  isCheckoutFunnel?: boolean;
}

const ContactsFieldsDisplay: FC<InputFieldDisplayProps> = ({
  id,
  values,
  error,
  isCpaFunnel = false,
  isCpaSkipFunnel = false,
  isCheckoutFunnel = false,
  onInputChange,
  onBlur,
}) => {
  return (
    <QuizBlockLayout currentBlockId={id}>
      <div className=" flex flex-col-reverse items-center	 w-full justify-center gap-4 desktop:p-8">
        {/* <img
          className="-scale-x-100 w-full max-w-[300px] object-contain	xs:hidden	"
          src={SupportImage}
          alt=""
        /> */}

        <div className="text-center max-w-[100%] w-[480px] flex-col flex gap-2">
          <h4
            color="red"
            className="text-center uppercase section-title xs:text-2xl text-xl"
          >
            {isCheckoutFunnel ? "Final Steps" : "You're Pre-Approved!"}
            {isCpaSkipFunnel && "Enjoy 63% Off Your First Month"}
          </h4>
          {isCpaSkipFunnel && (
            <p className="section-title !text-[17px]">
              Please submit the info below and continue to get pre-approved.
              Your discount will be automatically applied at checkout.
            </p>
          )}

          {!isCpaSkipFunnel && (
            <div>
              <p className="section-title !text-[17px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-5 h-5 inline text-purple/60 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  ></path>
                </svg>
                {isCpaFunnel
                  ? "Last Step: Please fill out the info below to view your results"
                  : isCheckoutFunnel
                  ? "If prescribed, our providers require your personal information on file."
                  : "Due to incredibly high demand, please verify your phone number and one of our team members will contact you to check for availability."}
              </p>
            </div>
          )}

          {!isCpaSkipFunnel && !isCheckoutFunnel && !isCpaFunnel && (
            <p className="text-base text-gray/80 mb-3">
              {/* Hey! You're pre-approved for a prescription for Semaglutide GLP-1!
            We just need some final questions. Please enter your information
            below to receive a call from a SkinnyRx member now. */}
              You can expect a call from us shortly.
            </p>
          )}

          <div className="flex direction-row gap-5 ">
            <InputField
              value={values["firstName"]}
              name="firstName"
              label="First Name"
              onChange={onInputChange}
              error={error["firstName"]}
              onBlur={onBlur}
              required
            />
            <InputField
              value={values["secondName"]}
              error={error["secondName"]}
              name="secondName"
              label="Last Name"
              onChange={onInputChange}
              onBlur={onBlur}
              required
            />
          </div>
          {isCheckoutFunnel && (
            <InputField
              name="dob"
              label="Date of birth"
              type="dob"
              value={values["dob"]}
              onChange={(event) => {
                const caret = event.target.selectionStart;
                const element = event.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });
                onInputChange(event);
              }}
              onBlur={onBlur}
              error={error["dob"]}
            />
          )}
          <InputField
            value={values["phone"]}
            error={error["phone"]}
            name="phone"
            onChange={onInputChange}
            onBlur={onBlur}
            label="Phone"
            type="phone"
            required
          />

          <InputField
            value={values["email"]}
            error={error["email"]}
            name="email"
            onChange={onInputChange}
            onBlur={onBlur}
            label="Email"
            required
          />

          {isCpaSkipFunnel && (
            <p className="text-xs text-left text-gray/80">
              By submitting this form, you agree to receive recurring automated
              promotional and personalized marketing text messages from SkinnyRx
              at the cell number & email address used when signing up. Consent
              is not a condition of any purchase. Reply HELP for help and STOP
              to cancel. Msg frequency varies. Msg and data rates may apply.
              View{" "}
              <a className="underline" href="https://skinnyrx.com/terms">
                Terms
              </a>
              {" & "}
              <a className="underline" href="https://skinnyrx.com/privacy">
                Privacy
              </a>
              .
            </p>
          )}
        </div>
      </div>
    </QuizBlockLayout>
  );
};

export default ContactsFieldsDisplay;
