import {BlockConfiguration} from '../../../types'
import SingleChoiceBlock from '../../../containers/QuizCustomBlocks/SingleChoiceBlock'

export const customSingleChoiceConfig: BlockConfiguration = {
  name: 'custom-single-choice',
  supports: {
    editable: true,
  },
  attributes: {
    question: {
      type: 'string',
    },
    prompt: {
      type: 'string',
    },
    classnames: {
      type: 'string',
      'default': 'without-footer-button',
    },
    descriptionBlock: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          content: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          customHTML: {
            type: 'string',
          },
        },
      },
    },
    options: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          value: {
            type: 'string',
          },
          label: {
            type: 'string',
          },
          innerBlock: {
            type: 'object',
            properties: {
              label: {
                type: 'string',
              },
              type: {
                type: 'string', // textarea
                'default': 'textarea',
              },
              placeholder: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    textarea: {
      type: 'object',
      properties: {
        label: {
          type: 'string',
        },
        placeholder: {
          type: 'string',
        },
      },
    },
    note: {
      type: 'string',
    },
    conditionalBlocks: {
      type: 'object',
      additionalProperties: {
        type: 'array',
        items: {
          type: 'object',
        },
      },
    },
    required: {
      type: 'boolean',
    },
    isAnswered: {
      type: 'boolean',
    },
  },
  display: SingleChoiceBlock,
}
