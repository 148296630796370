const USER_DATA_KEY = "quizUserData";

export const saveUserInfoValues = (values: Record<string, string>) => {
  const oldValues = localStorage.getItem(USER_DATA_KEY) || "{}";
  const oldValuesParsed = JSON.parse(oldValues) || {};

  localStorage.setItem(
    USER_DATA_KEY,
    JSON.stringify({ ...oldValuesParsed, ...values })
  );
};

export const userInfoToQueryParams = () => {
  try {
    const values = localStorage.getItem(USER_DATA_KEY) || "{}";

    const params = new URLSearchParams(values);

    return params.toString();
  } catch (e) {
    console.error(e);
    return "";
  }
};
