import Cookies from "js-cookie";
import { memo, useEffect } from "react";
import { CookiesKey, QuizAffiliateProvider } from "../../types/cookies";
import { Helmet } from "react-helmet";

const QuizTracking = memo(() => {
  const transactionId = Cookies.get(CookiesKey.QUIZ_TRANSACTION_ID);

  const cpaAffiliateProvider = Cookies.get(
    CookiesKey.QUIZ_AFFILIATE_CMP
  ) as QuizAffiliateProvider;

  useEffect(() => {
    if (cpaAffiliateProvider === QuizAffiliateProvider.AP_CPA) {
      window?.fbq?.("init", "1135726667481333");
      window?.fbq?.("init", "436103438892412");
      window?.fbq?.("init", "2389439141250150");
      window?.fbq?.("init", "917049193283139");

      window?.fbq?.("track", "PageView");

      window?.fbq?.("trackSingleCustom", "2389439141250150", "QuizStart");
      window?.fbq?.("trackSingleCustom", "917049193283139", "QuizStart");
      window?.fbq?.("trackSingleCustom", "1135726667481333", "QuizStart");
      window?.fbq?.("trackSingleCustom", "436103438892412", "QuizStart");
    }
  }, []);

  return (
    <>
      {(cpaAffiliateProvider === QuizAffiliateProvider.BW ||
        cpaAffiliateProvider === QuizAffiliateProvider.BW_CPA) && (
        <iframe
          src={`https://www.sau8grg.com/?nid=1488&event_id=2868&transaction_id=${transactionId}`}
          height="1"
          width="1"
          frameBorder="0"
          title="tr"
        />
      )}
      {(cpaAffiliateProvider === QuizAffiliateProvider.TO_CPA ||
        cpaAffiliateProvider === QuizAffiliateProvider.TO) && (
        <Helmet>
          <script>
            {`
              ;!function(s,c,a,l,e){function r(t){return btoa(encodeURIComponent(t))}function i(t){i.l?i[t]&&i[t
            ].apply(i,arguments):i.q.push(arguments)}var t=l.split("."),d=s.location,s=(i.v="1.0.0",i.q=[],i.s="",
            i.config=function(t,n){i.s=n},i.track=function(t,n,o){var r=new XMLHttpRequest;
            r.onreadystatechange=function(){var t;4==this.readyState&&((t=c.createElement("iframe")
            ).height=t.width=0,t.style="display:none;visibility:hidden;",
            t.sandbox="allow-forms allow-scripts allow-same-origin",t.srcdoc=this.responseText,(c.body||c.head
            ).appendChild(t),setTimeout(function(){return t.remove()},3e4))},r.open("GET",e+i.state(!1,n,o||{}),!0),
            r.send()},i.state=function(t,n,o){return"?v="+i.v+"&h="+l+"&u="+r(d.href)+"&r="+r(c.referrer)+(
            n?"&s="+i.s+"&i="+(c.cookie.match(RegExp(decodeURIComponent("%5Cb")+l+"=([^;]+)"))||[])[1
            ]+"&e="+n+"&c="+r(JSON.stringify(o)):"")},s[t[0]]=s[t[0]]||{});s.io||(s.io=i,(t=c.createElement(a)
            ).async=!0,t.src=e+"sdk.js"+i.state(),c.addEventListener("beforeunload",t.onerror=function(){i.l=!0;for(
            var t=0;t<i.q.length;++t)i.apply(i,i.q[t]);i.q=[]}),(s=c.getElementsByTagName(a)[0]
            ).parentNode.insertBefore(t,s))}(window,document,"script","theoffer.io","https://api.secureoffer.io/");

            theoffer.io("config", "${
              cpaAffiliateProvider === QuizAffiliateProvider.TO_CPA
                ? "endpUpLe4TqhZT7l7c8tz"
                : "4iqsC5icRG1Em4vWvvs6by"
            }");
            theoffer.io("track", "ViewContent");
              `}
          </script>
        </Helmet>
      )}
    </>
  );
});

export { QuizTracking };
