import {FC} from 'react'
import {IoWarning} from 'react-icons/io5'
import {ErrorScreenBlockAttributes} from '../../../containers/QuizCustomBlocks/ErrorScreenBlock'

interface ErrorScreenDisplayProps extends ErrorScreenBlockAttributes {
}

const ErrorScreenDisplay: FC<ErrorScreenDisplayProps> = ({title, message}) => {
  return (
    <>
      <h2 className='error-title'>{title}</h2>
      <div className='error-message-container'>
        <div className='mb-3 sm:mb-4 lg:mb-5'>
          <IoWarning className='w-12 sm:w-20 lg:w-24 h-12 sm:h-20 lg:h-24 text-tomato'/>
        </div>
        <p>{message}</p>
      </div>
    </>
  )
}

export default ErrorScreenDisplay
