import { useEffect } from "react";
import { QuizBlockProps } from "../../types";
import DoctorPrescribeBannerDisplay from "../../components/QuizForm/CustomBlocks/DoctorPrescribeBannerDisplay";

interface ContactsBlockProps extends QuizBlockProps<{}> {}

const DoctorPrescribeBannerBlock = ({
  id,
  setIsAnswered,
  setVal,
}: ContactsBlockProps) => {
  useEffect(() => {
    setVal({ answer: true });
    setIsAnswered(true);
  }, []);

  return <DoctorPrescribeBannerDisplay id={id} />;
};

export default DoctorPrescribeBannerBlock;
