import SingleChoiceDisplay from "../../components/QuizForm/CustomBlocks/SingleChoiceDisplay";
import { FC, useCallback, useEffect, useState } from "react";
import { QuizBlockProps } from "../../types";
import { useQuiz } from "../../contexts/QuizContext";

interface SingleChoiceBlockAttribute {
  options: {
    value: string;
    label: string;
    innerBlock?: { label: string; type: string };
  }[];
  classnames?: string;
  question?: string;
  prompt?: string;
  descriptionBlock?: {
    title?: string;
    content?: string[];
    customHTML?: string;
  }[];
  textarea?: { label: string; placeholder?: string };
  note?: string;
  conditionalBlocks?: Record<string, any>;
}

interface SingleChoiceBlockProps
  extends QuizBlockProps<SingleChoiceBlockAttribute> {
  val: { answer?: string; inputValue?: string } | undefined;
}

const SingleChoiceBlock: FC<SingleChoiceBlockProps> = ({
  setIsAnswered,
  val,
  setVal,
  next,
  id,
  attributes,
}) => {
  const {
    question,
    prompt,
    options,
    descriptionBlock,
    classnames,
    textarea,
    note,
    conditionalBlocks,
  } = attributes;
  const [value, setValue] = useState(val);

  const { hasResponded, currentBlockId, setHasResponded } = useQuiz();

  useEffect(() => {
    if (value?.answer) {
      const selectedOption = options.find(
        (option) => option.value === value.answer
      );

      if (!selectedOption?.innerBlock) {
        setIsAnswered(true);
        setVal(value);
        return;
      }

      setIsAnswered(!!value?.inputValue);
      setVal(value?.inputValue ? value : undefined);
    }
  }, [value?.answer, value?.inputValue]);

  useEffect(() => {
    if (val && val.answer === value?.answer && currentBlockId === id) {
      // const autoSwitching = classnames === 'without-footer-button' && hasResponded
      // const autoSwitching = "without-footer-button";
      const blockHasInnerBlock = options.some((opiton) => !!opiton.innerBlock);
      const autoSwitching =
        classnames === "without-footer-button" && hasResponded;
      const singleAnswer = options.find(
        (option) => option.value === val?.answer && !option.innerBlock
      );
      const timeout = conditionalBlocks ? 800 : 500;

      if (
        hasResponded &&
        (autoSwitching || (singleAnswer && blockHasInnerBlock))
      ) {
        setTimeout(() => next(), timeout);
        setHasResponded(false);
      }
    }
  }, [val, currentBlockId, hasResponded]);

  const handleChange = useCallback((value: string) => {
    setHasResponded(true);
    setValue((prev) => ({ ...(prev || {}), answer: value }));
  }, []);

  const handleTextareaInputChange = useCallback((value: string) => {
    setValue((prev) => ({ ...(prev || {}), inputValue: value }));
  }, []);

  return (
    <SingleChoiceDisplay
      id={id}
      question={question}
      prompt={prompt}
      descriptionBlock={descriptionBlock}
      options={options}
      value={value?.answer}
      textarea={textarea}
      note={note}
      textareaValue={value?.inputValue}
      onChange={handleChange}
      onChangeTextarea={handleTextareaInputChange}
    />
  );
};

export default SingleChoiceBlock;
